<template>
    <div>
        <treeselect v-model="value"
                    placeholder=""
                    :multiple="multiple"
                    :options="values"
                    :required="required"
                    valueConsistsOf="LEAF_PRIORITY"
        />
    </div>
</template>
<script>
import {abstractField} from 'vue-form-generator'
import FormMixin from "vue-titan-form/src/form/FormMixin";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    mixins: [abstractField, FormMixin],
    components: {Treeselect},
    computed: {
        multiple() {
            return this.schema.multiple === undefined ? true : this.schema.multiple
        },
        required() {
            return this.schema.required === undefined ? false : this.schema.required
        },
        values: {
            cache: false,
            get() {
                if (typeof this.schema.values === 'function') {
                    return this.schema.values();
                } else if (this.schema.codeBook !== undefined && this.schema.codeBook !== null) {
                    return this.$store.getters['codeBook/get'](this.schema.codeBook)
                }
                return this.schema.values === undefined ? [] : this.schema.values
            }
        },
        placeholder() {
            return this.schema.placeholder === undefined ? '' : this.schema.placeholder
        },
    },
    methods: {

    },
    data() {
        return {
            reloading: false,
        }
    }
}
</script>
<style lang="scss">
@import '~vue-multiselect/dist/vue-multiselect.min.css';

.titan-select {
    min-height: 38px;

    &.multiselect--disabled {
        .multiselect__select {
            display: none;
        }
    }

    .multiselect__content-wrapper,
    .multiselect__tags {
        border-color: #ced4da;
    }

    .multiselect__tags {
        min-height: 38px;
    }
}
</style>
