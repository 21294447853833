import api from '../api';

export default {
    namespaced: true,
    state: {
    },
    actions: {
        codeBook(store, stepId) {
            return new Promise((resolve, reject) => {
                api.get('kanban-step-fail-status/' + stepId).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject()
                });
            })
        },
    },
    mutations: {

    }
}
