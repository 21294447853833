import api, {defaultPaginator} from '../api';

const baseUrl = 'contact-recommendations'

export default {
    namespaced: true,
    state: {
        recommendationsList: {
            loading: false,
            items: [],
            paginator: {...defaultPaginator},
        },
    },
    actions: {
        load(store, data) {
            store.commit('setListLoading', data.page)

            const conditions = []

            if (data.search && data.search.length > 2) {
                conditions.push({
                    key: "name",
                    type: "fulltext",
                    conjunction: "AND",
                    minLength: 2,
                    value: data.search
                })
            }

            api.listData(store, baseUrl + '/list/' + data.page + '/20', {conditions: conditions, ...data}).then((data) => {
                store.commit('setList', data)
            })
        },
    },
    mutations: {
        setListLoading(state, page) {
            state.recommendationsList.loading = true
            if (page === 1) {
                state.recommendationsList.items = []
            }
        },
        setList(state, data) {
            state.recommendationsList.items = data.paginator.page === 1
                ? data.items
                : [...state.recommendationsList.items, ...data.items]

            state.recommendationsList.paginator = data.paginator
            state.recommendationsList.loading = false
        },
    }
}
