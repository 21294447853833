import api from '../api';

export default {
    namespaced: true,
    state: {},
    actions: {
        list(store, data = {}) {
            return api.postItem(store, 'goal/list/' + data.year + '/1/13', data)
        },
        update(store, data) {
            const month = data.month === null ? '' : '/' + data.month;
            return api.editItem(store, 'goal/' + data.year + month, data, null, () => {
                store.dispatch('alert/success', 'Your goal was updated', {root: true});
            })
        },
    },
    mutations: {}
}
