import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const publicRole = []
const userOnlyRole = ['user']
const assistantOnlyRole = ['assistant']
const userRole = [...userOnlyRole, ...assistantOnlyRole]
const seminarRole = ['seminar']
const calendarRole = ['calendar']
const manageRanking = ['manageRanking']
const translator = ['translator']
const callScript = ['callScript']
const structure = ['structure']
const beta = ['beta']
const adminOnlyRole = [...translator, ...callScript, ...structure]


const routes = [
    {
        path: '/public',
        component: () => import('../views/public/Public.vue'),
        meta: {roles: publicRole},
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import('../views/public/Login'),
                meta: {roles: publicRole},
            }, {
                path: 'forgotten-password',
                name: 'forgottenPassword',
                component: () => import('../views/public/LostPassword'),
                meta: {roles: publicRole},
            }, {
                path: 'reset-password/:id/:hash',
                name: 'resetPassword',
                component: () => import('../views/public/ResetPassword'),
                meta: {roles: publicRole},
            }
        ]
    },
    {
        path: '/',
        component: () => import('../views/private/Private.vue'),
        children: [
            {
                path: '/',
                redirect: {name: 'dashboard'},
                meta: {roles: userRole},
            }, {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('../views/private/dashboard/DashboardPage.vue'),
                meta: {roles: userRole},
            }, {
                path: 'notes/:page/:category?',
                name: 'notes',
                component: () => import('../views/private/notes/NotePage.vue'),
                meta: {roles: beta},
            }, {
                path: 'calendar/:startDate',
                name: 'calendar',
                component: () => import('../views/private/calendar/CalendarPage.vue'),
                meta: {roles: calendarRole},
            }, {
                path: 'group',
                component: () => import('../views/private/profile/groups/GroupWrapper.vue'),
                meta: {roles: userOnlyRole},
                children: [
                    {
                        path: 'list/:page',
                        name: 'groupList',
                        component: () => import('../views/private/profile/groups/pages/GroupListPage.vue'),
                        meta: {roles: userOnlyRole},
                    }
                ]
            }, {
                path: 'structure',
                component: () => import('../views/private/structure/structure/StructureWrapper.vue'),
                meta: {roles: userOnlyRole},
                children: [
                    {
                        path: 'edit',
                        name: 'editStructure',
                        component: () => import('../views/private/structure/structure/StructureTree.vue'),
                        meta: {roles: structure},
                    }, {
                        path: ':userId',
                        name: 'structure',
                        component: () => import('../views/private/structure/structure/StructureTree.vue'),
                        meta: {roles: userOnlyRole},
                    }
                ]
            }, {
                path: 'report/:slug',
                name: 'report',
                component: () => import('../views/private/reports/Reports.vue'),
                meta: {roles: userRole},
            }, {
                path: 'seminar',
                component: () => import('../views/private/seminar/Seminar.vue'),
                meta: {roles: seminarRole},
                children: [
                    {
                        path: 'create',
                        name: 'createSeminar',
                        component: () => import('../views/private/seminar/components/ManageForm.vue'),
                        meta: {roles: seminarRole},
                    }, {
                        path: 'list/:page',
                        name: 'seminarList',
                        component: () => import('../views/private/seminar/List.vue'),
                        meta: {roles: seminarRole},
                    }, {
                        path: 'detail/:id',
                        component: () => import('../views/private/seminar/Manage.vue'),
                        meta: {roles: seminarRole},
                        children: [
                            {
                                path: 'edit',
                                name: 'editSeminar',
                                component: () => import('../views/private/seminar/components/ManageForm.vue'),
                                meta: {roles: seminarRole},
                            }, {
                                path: 'seminarRecommendations/page/:page',
                                name: 'seminarRecommendations',
                                component: () => import('../views/private/seminar/components/Recommendations.vue'),
                                meta: {roles: seminarRole},
                            }
                        ]
                    }
                ]
            }, {
                path: 'system-elements',
                component: () => import('../views/private/system-elements/Callparty.vue'),
                meta: {roles: userRole},
                children: [
                    {
                        path: 'list/:page',
                        name: 'callPartyList',
                        redirect: {
                            name: 'systemElements',
                            params: {
                                type: 'future'
                            }
                        },
                        meta: {roles: userRole},
                    }, {
                        path: 'time-line/:type/:elementType?',
                        name: 'systemElements',
                        component: () => import('../views/private/system-elements/List.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'detail/:id',
                        name: 'systemElement',
                        component: () => import('../views/private/system-elements/SystemElementDetail.vue'),
                        meta: {roles: userRole},
                    }
                ]
            }, {
                path: 'call-list',
                component: () => import('../views/private/callList/CallListWrapper.vue'),
                meta: {roles: userOnlyRole},
                children: [
                    {
                        path: 'history',
                        name: 'callListHistory',
                        component: () => import('../views/private/callList/HistoryPage.vue'),
                        meta: {roles: userOnlyRole},
                    }, {
                        path: 'first',
                        name: 'firstCallList',
                        component: () => import('../views/private/callList/FirstCallList.vue'),
                        meta: {roles: userOnlyRole},
                    }, {
                        path: ':callParty',
                        name: 'callList',
                        component: () => import('../views/private/callList/CallListPage.vue'),
                        meta: {roles: userOnlyRole},
                    }
                ],
            }, {
                path: 'database',
                component: () => import('../views/private/database/DatabaseWrapper.vue'),
                meta: {roles: userOnlyRole},
                children: [
                    {
                        path: 'list/:page',
                        name: 'databaseList',
                        component: () => import('../views/private/database/DatabaseList.vue'),
                        meta: {roles: userOnlyRole},
                    }, {
                        path: 'reminders/:type',
                        name: 'contactReminders',
                        component: () => import('../views/private/database/ContactReminders.vue'),
                        meta: {roles: userOnlyRole},
                    }, {
                        path: 'service-list',
                        name: 'serviceList',
                        component: () => import('../views/private/database/ContactServiceList.vue'),
                        meta: {roles: userOnlyRole},
                    }, {
                        path: 'client-tree',
                        name: 'clientTree',
                        component: () => import('../views/private/database/ClientTreePage.vue'),
                        meta: {roles: userOnlyRole},
                    }, {
                        path: 'recommendations',
                        name: 'contactRecommendations',
                        component: () => import('../views/private/database/ContactRecommendations.vue'),
                        meta: {roles: userOnlyRole},
                    }, {
                        path: 'add',
                        name: 'createContact',
                        component: () => import('../views/private/database/CreateContact.vue'),
                        meta: {roles: userOnlyRole},
                    }, {
                        path: 'import',
                        name: 'importContacts',
                        component: () => import('../views/private/database/ImportPage'),
                        meta: {roles: userOnlyRole},
                    }, {
                        path: 'contact/:id',
                        name: 'contact',
                        component: () => import('../views/private/database/detail/Contact.vue'),
                        meta: {roles: userOnlyRole},
                        children: [
                            {
                                path: 'notes',
                                name: 'contactNotes',
                                component: () => import('../views/private/database/detail/tabs/Notes.vue'),
                                meta: {roles: userOnlyRole},
                            }, {
                                path: 'edit',
                                name: 'contactManage',
                                component: () => import('../views/private/database/detail/tabs/Manage.vue'),
                                meta: {roles: userOnlyRole},
                            }, {
                                path: 'tree',
                                name: 'contactTree',
                                component: () => import('../views/private/database/detail/tabs/ContactTree.vue'),
                                meta: {roles: userOnlyRole},
                            }
                        ]
                    }
                ]
            }, {
                path: 'profile',
                component: () => import('../views/private/profile/Profile.vue'),
                meta: {roles: userOnlyRole},
                children: [
                    {
                        path: 'goals/:year',
                        name: 'goals',
                        component: () => import('../views/private/profile/GoalsPage.vue'),
                        meta: {roles: userOnlyRole},
                    }, {
                        path: 'edit',
                        name: 'editProfile',
                        component: () => import('../views/private/profile/EditProfile.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'change-password',
                        name: 'changePassword',
                        component: () => import('../views/private/profile/ChangePassword.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'users',
                        component: () => import('../views/private/admin/users/Users.vue'),
                        meta: {roles: userOnlyRole},
                        children: [
                            {
                                path: 'list/:page/:parent?',
                                name: 'users',
                                component: () => import('../views/private/admin/users/List.vue'),
                                meta: {roles: userOnlyRole},
                            }, {
                                path: 'edit/:id/:slug',
                                name: 'editUser',
                                component: () => import('../views/private/admin/users/Manage.vue'),
                                meta: {roles: userOnlyRole},
                            }, {
                                path: 'create-user',
                                name: 'createUser',
                                component: () => import('../views/private/admin/users/Manage.vue'),
                                meta: {roles: userOnlyRole},
                            }, {
                                path: 'user-dashboard/:id',
                                name: 'userDashboard',
                                component: () => import('../views/private/dashboard/DashboardPage.vue'),
                                meta: {roles: userOnlyRole},
                            }
                        ]
                    }
                ]
            }, {
                path: 'meeting',
                component: () => import('../views/private/meeting/Meeting.vue'),
                meta: {roles: userRole},
                children: [
                    {
                        path: 'list/:page',
                        name: 'meetings',
                        component: () => import('../views/private/meeting/components/List.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'edit/:id',
                        name: 'editMeeting',
                        component: () => import('../views/private/meeting/components/Edit.vue'),
                        meta: {roles: userRole},
                    }
                ]
            }, {
                path: 'ranking',
                component: () => import('../views/private/ranking/RankingPage.vue'),
                meta: {roles: manageRanking},
                children: [
                    {
                        path: 'users',
                        name: 'rankingUsers',
                        component: () => import('../views/private/ranking/RankingUsersPage.vue'),
                        meta: {roles: manageRanking},
                    }, {
                        path: 'contacts/:userId/:year/:month',
                        name: 'rankingContacts',
                        component: () => import('../views/private/ranking/RankingContactsPage.vue'),
                        meta: {roles: manageRanking},
                    }
                ]
            }, {
                path: 'kanban',
                component: () => import('../views/private/kanban/KanbanPageWrapper.vue'),
                meta: {roles: userOnlyRole},
                children: [
                    {
                        path: 'history',
                        name: 'kanbanHistory',
                        component: () => import('../views/private/kanban/KanbanHistoryPage.vue'),
                        meta: {roles: userOnlyRole},
                    }, {
                        path: ':id',
                        name: 'kanban',
                        component: () => import('../views/private/kanban/KanbanPage.vue'),
                        meta: {roles: userOnlyRole},
                    }
                ]
            }, {
                path: 'admin',
                component: () => import('../views/private/admin/Admin.vue'),
                meta: {roles: userOnlyRole},
                children: [
                    {
                        path: '',
                        redirect: {name: 'users', params: {page: 1}},
                        meta: {roles: userOnlyRole},
                    },
                    {
                        path: 'redirect',
                        name: 'adminRedirectPage',
                        component: () => import('../views/private/admin/AdminRedirectPage.vue'),
                        meta: {roles: adminOnlyRole},
                    }, {
                        path: 'translator/:page',
                        name: 'translator',
                        component: () => import('../views/private/admin/translator/Translator.vue'),
                        meta: {roles: translator},
                    }, {
                        path: 'call-script',
                        component: () => import('../views/private/admin/callScript/callScript.vue'),
                        meta: {roles: callScript},
                        children: [
                            {
                                path: 'create',
                                name: 'createCallScript',
                                component: () => import('../views/private/admin/callScript/Manage'),
                                meta: {roles: callScript},
                            }, {
                                path: 'list/:page',
                                name: 'callScriptList',
                                component: () => import('../views/private/admin/callScript/List'),
                                meta: {roles: callScript},
                            }, {
                                path: 'edit/:id',
                                name: 'editCallScript',
                                component: () => import('../views/private/admin/callScript/Manage'),
                                meta: {roles: callScript},
                            }
                        ]
                    }
                ]
            }, {
                path: 'tutorials',
                name: 'tutorials',
                component: () => import('../views/private/tutorials/TutorialListPage.vue'),
                meta: {roles: userRole},
            }
        ]
    }, {
        path: '*',
        redirect: {
            name: 'dashboard'
        },
        meta: {roles: publicRole},
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export const hasRouteAccess = (user, route) => {
    const roles = route.meta.roles

    if (roles === undefined) {
        throw new Error('Roles are not defined for route');
    }

    // Page is public and user is not logged in
    if (user === null && roles.length === 0) {
        return true
    }

    // Page is private and user is NOT logged in
    if (user === null && roles.length > 0) {
        return {name: 'login'}
    }

    // Has required role
    if (roles.includes(user.position.role) === true) {
        return true
    }

    // Ranking access
    if (roles.includes('manageRanking') && user.rankingAccess !== 'blocked') {
        return true;
    }

    // Calendar access
    if (roles.includes('calendar') && user.calendar === true) {
        return true;
    }

    // callScript access
    if (roles.includes('callScript') && user.editCallScript === true) {
        return true;
    }

    // Structure access
    if (roles.includes('structure') && user.editStructure === true) {
        return true;
    }
    // Beta access
    if (roles.includes('beta') && user.beta === true) {
        return true;
    }

    // Translator access
    if (roles.includes('translator') && store.getters["account/hasPermission"]('TranslateTitan', 'R')) {
        return true;
    }

    // Has access to seminar based on permission
    if (roles.includes('seminar') && store.getters["account/hasPermission"]('SeminarTitan', 'R')) {
        return true
    }

    return {name: 'dashboard'}

}

const resolveAccess = (user, route, next) => {
    const response = hasRouteAccess(user, route)

    if (response === true) {
        next()
    } else {
        router.push(response)
    }
}

router.beforeEach((to, from, next) => {
    if (store.state.account.user === null) {
        store.dispatch('account/get').then((data) => {
            resolveAccess(data.loggedIn === true ? data.account : null, to, next)
        }, () => {
            resolveAccess(null, to, next)
        })
    } else {
        resolveAccess(store.state.account.user, to, next)
    }
})

const originalPush = router.push

router.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err
        }
        return Promise.reject(err)
    })
}

export default router
