import api from '../api';

export default {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        update(store, data) {
            return api.edit(store, 'contact-note', data)
        },
        updateKanbanStepNote(store, data) {
            return api.edit(store, 'contact-note/kanban-step', data)
        }
    },
    mutations: {
        setItem() {
            // part of edit method
        }
    }
}
