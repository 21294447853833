import api from '../api';

const baseUrl = 'callparty';

export default {
    namespaced: true,
    state: {
        callParty: null,
        loading: true,
        members: [],
        myTeam: []
    },
    actions: {
        list(store, data) {
            return api.list(store, baseUrl + '/list', data);
        },
        memberList(store, data) {
            store.state.members = []
            store.state.myTeam = []
            store.dispatch('members', data).then((response) => {
                store.state.members = response.data.members
                store.state.myTeam = response.data.myTeam
            })
        },
        members(store, data) {
            const onlyMy = data.onlyMy === true ? '/1' : ''
            return api.get('callparty/members/' + data.id + onlyMy);
        },
        detail(store, id) {
            store.commit('resetCallParty')

            return api.getItem(store, baseUrl + '/' + id, ({data}) => { // ok
                store.commit('setCallParty', data);
            })
        },
        addMembers(store, data) {
            return api.editItem(
                store,
                baseUrl + '/assign-users/' + data.id,
                data,
                'Members were assigned!',
                () => {
                    store.dispatch('memberList', {id: data.id})
                }
            )
        },
        close(store, id) {
            return api.editItem(store, baseUrl + '/close/' + id, {}, 'Call party was closed')
        },
    },
    mutations: {
        setCallParty(state, data) {
            state.callParty = {...data};
        },
        resetCallParty(state) {
            state.callParty = null;
        },
    }
}
