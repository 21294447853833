import api from '../api';

export default {
    namespaced: true,
    state: {
        seminar: null,
        loading: true,
    },
    actions: {
        list(store, data) {
            const page = data.page || 1
            return new Promise((resolve, reject) => {
                api.post('seminar/list/' + page + '/' + 10, data).then((response) => {
                    resolve(response.data)
                }).catch(() => {
                    reject()
                });
            })
        },
        attendee(store, data) {
            const page = data.page || 1
            const seminarId = data.seminarId || ''
            return new Promise((resolve, reject) => {
                api.post('seminar-attandee-contact/list/' + seminarId + '/' + page + '/' + 10, data).then((response) => {
                    resolve(response.data)
                }).catch(() => {
                    reject()
                });
            })
        },
        attendeeStats(store, id) {
            return api.get('seminar-attandee-contact/stats/' + id);
        },
        detail(store, id) {
            store.commit('resetseminar')
            return new Promise((resolve, reject) => {
                api.get('seminar/' + id).then((response) => {
                    store.commit('setseminar', response.data);
                    resolve()
                }).catch((error) => {
                    store.dispatch('alert/error', error.response.data.status, {root: true});
                    reject()
                });
            });
        },
        create(store) {
            return new Promise((resolve, reject) => {
                api.post('seminar', store.state.seminar).then((response) => {
                    store.dispatch('alert/success', 'Seminar was created!', {root: true});
                    resolve(response.data)
                }).catch((error) => {
                    store.dispatch('alert/error', error.response.data.status, {root: true});
                    reject()
                });
            })
        },
        edit(store) {
            return new Promise((resolve, reject) => {
                api.put('seminar/' + store.state.seminar.id, store.state.seminar).then((response) => {
                    store.dispatch('alert/success', 'Seminar was updated!', {root: true});
                    resolve(response.data)
                }).catch((error) => {
                    store.dispatch('alert/error', error.response.data.status, {root: true});
                    reject()
                });
            })
        },
    },
    mutations: {
        setseminar(state, data) {
            state.seminar = {...data};
        },
        resetseminar(state) {
            state.seminar = null;
        },
    }
}
