import api, {defaultPaginator} from '../api';

const baseUrl = 'kanban-calendar'

export default {
    namespaced: true,
    state: {

    },
    actions: {
        get(store, contactKanbanId) {
            return api.getItem(store, baseUrl + '/' + contactKanbanId)
        },
        setDate(store, data) {
            return api.editItem(store, baseUrl + '/date/' + data.id, data, 'Date has been set')
        }
    },
    mutations: {

    }
}
