<template>
    <div>
        <div class="gallery-wrapper">
            <div v-for="image in gallery"
                 class="gallery-image"
                 :class="{'gallery-image-selected': image.id === id}"
                 @click="value = image"
            >
                <font-awesome-icon icon="check" class="active-icon" v-if="image.id === id"/>
                <img :src="image.link + '&height=70'" :alt="image.tags"/>
            </div>
        </div>
        <label>
            <div class="btn btn-sm btn-success">
                <font-awesome-icon icon="spinner" :spin="true" v-if="$store.state.plan.uploading"/>
                <font-awesome-icon icon="cloud-upload-alt" v-if="!$store.state.plan.uploading"/>
                {{ t('Upload image') }}
            </div>
            <input type="file" class="d-none" @change="uploadImages($event.target.files);" multiple>
        </label>
    </div>
</template>
<script>
import {abstractField} from 'vue-form-generator'
import FormMixin from "vue-titan-form/src/form/FormMixin";
import {getBase64} from "@/store/api";

export default {
    mixins: [abstractField, FormMixin],
    components: {},
    mounted() {
        this.load()
    },
    watch: {
        tags() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.load()
            }, 300);

        }
    },
    computed: {
        multiple() {
            return this.schema.multiple === undefined ? true : this.schema.multiple
        },
        required() {
            return this.schema.required === undefined ? false : this.schema.required
        },
        name() {
            return this.schema.name || null
        },
        id() {
            return this.value === '' || this.value === null ? null : this.value.id
        },
        tags() {
            return this.name === null ? '' : this.model[this.name];
        }
    },
    methods: {
        load() {
            this.$store.dispatch('gallery/search', {
                id: this.id,
                tags: this.tags
            }).then((data) => {
                this.gallery = data;
            })
        },
        uploadImages(files) {
            this.$store.commit('gallery/setUploading', true)
            files.forEach((file) => {
                this.uploadFile(file)
            })
        },
        uploadFile(file) {
            getBase64(this.$store, file).then((data) => {
                this.$store.dispatch('gallery/uploadImage', {
                    base64: data,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    tags: this.tags,
                }).then(() => {
                    this.load()
                })
            })
        },
    },
    data() {
        return {
            timeout: null,
            gallery: [],
        }
    }
}
</script>
<style lang="scss">
.gallery-wrapper {
    $size: 70px;
    $borderSize: 2px;

    .gallery-image {
        position: relative;
        height: $size;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        display: inline-block;

        .active-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            color: #fff;
            z-index: 2;
            margin-left: -10px;
            margin-top: -10px;
            font-size: 20px;
        }

        > img {
            height: $size;
            display: block;
            border-radius: 10px;
        }

        &-selected {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: #009DFF;
                opacity: 0.3;
                border-radius: 10px;
                z-index: 1;
            }
        }
    }
}
</style>
