import api from '../api';

const baseUrl = 'contact-kanban-performance'

export default {
    namespaced: true,
    state: {
    },
    actions: {
        performance(store, data) {
            return api.editItem(store, baseUrl + '/earned/' + data.id, data, 'Kanban performance was updated!')
        },
        contracts(store, data) {
            return api.editItem(store, baseUrl + '/contracts/' + data.id, data, 'Kanban contracts were updated!')
        },
    },
    mutations: {
        open(state, id) {
            state.openedId = state.openedId === id ? null : id
        }
    }
}
