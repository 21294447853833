import api from '../api';

const baseUrl = 'contact-kanban-recommendation'

export default {
    namespaced: true,
    state: {
    },
    actions: {
        update(store, data) {
            return api.editItem(store, baseUrl + '/' + data.id, data, 'Kanban recommendations was updated!')
        },
    },
    mutations: {
        open(state, id) {
            state.openedId = state.openedId === id ? null : id
        }
    }
}
