import api from '../api';

const defaultContact = {
    id: null,
    parent: {
        id: null,
        name: '',
    },
    delegated: false,
    name: '',
    phone: '',
    email: '',
    note: '',
    createdOn: null,
    status: null,
    plan: [],
    city: null,
    income: null,
    fromCity: null,
    profession: null,
    source: null,
    purpose: null,
    age: null,
    contactTag: [],
    level: 1,
    recommendationLink: '',
};

const nodeIcon = (color) => {
    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 497 497">' +
        '<circle fill="white" cx="248.5" cy="248.5" r="248"/>' +
        '<path fill="' + color + '" d="M248,8C111,8,0,119,0,256S111,504,248,504,496,393,496,256,385,8,248,8Zm0,96a88,88,0,1,1-88,88A88,88,0,0,1,248,104Zm0,344a191.63,191.63,0,0,1-146.5-68.2C120.3,344.4,157.1,320,200,320a24.47,24.47,0,0,1,7.1,1.1A132.4,132.4,0,0,0,248,328a131.9,131.9,0,0,0,40.9-6.9A24.47,24.47,0,0,1,296,320c42.9,0,79.7,24.4,98.5,59.8A191.63,191.63,0,0,1,248,448Z" transform="translate(0.5 -7.5)"/>' +
        '</svg>';
};

const baseUrl = 'contact';

export default {
    namespaced: true,
    state: {
        contact: {...defaultContact},
        contacts: [],
        contactPaginator: {
            page: 1,
            pages: 2,
        },
        network: {
            nodes: [],
            links: [],
        },
        loading: true,
        importData: null,
        importInfo: {
            perPage: 100,
            contactCount: 0,
            page: 1,
            pages: 0,
            importing: false,
            importedFinished: false,
            error: false,
        },
    },
    getters: {},
    actions: {
        save(store) {
            return store.state.contact.id === null ? store.dispatch('create') : store.dispatch('update');
        },
        create(store) {
            return api.postItem(store, baseUrl, {...store.state.contact}, 'Contact was created!', (response) => {
                store.commit('setContact', response.data);
                store.commit('contactList/resetList', null, {root: true})
            })
        },
        update(store) {
            const data = {...store.state.contact};
            return store.dispatch('updateContact', data);
        },
        merge(store, data) {
            const url = baseUrl + '/merge/' + data.contactId + '/' + data.mergeContactId
            return api.editItem(store, url, {}, 'Contacts merged', () => {
                store.commit('contactList/resetList', null, {root: true})
            })
        },
        createdReport() {
            return api.get('contact/created-report')
        },
        updateContact(store, data) {
            return api.editItem(store, baseUrl + '/' + data.id, data, 'Contact was updated!', (response) => {
                store.commit('setContact', response.data);
                // store.commit('contactList/resetList', null, {root: true})
            })
        },
        get(store, id) {
            store.commit('setContact', {...defaultContact});
            return api.getItem(store, baseUrl + '/' + id, (response) => {
                store.commit('setContact', response.data);
            })
        },
        archive(store, id) {
            return api.editItem(store, baseUrl + '/archive/' + id, {}, 'Contact archive was updated!', () => {
                store.commit('contactList/resetList', null, {root: true})
            })
        },
        search(store, search) {
            const conditions = {
                page: 1,
                conditions: [{
                    key: 'name',
                    type: 'fulltext',
                    likeType: '%_like_',
                    value: search,
                }]
            };

            return new Promise((resolve, reject) => {
                api.list(store, baseUrl + '/list', conditions).then(data => {
                    resolve(data.items);
                }, (error) => {
                    reject(error);
                });
            });
        },
        tree(store, id) {
            return new Promise((resolve) => {
                api.get('contact/tree/' + id).then((res) => {
                    resolve(res.data);
                });
            });
        },
        clientTree(store) {
            return api.getItem(store, 'contact/client-tree')
        },
        importFile(store, file) {
            return api.upload(store, 'contact-import/file', file, (data) => {
                store.commit('setImportContacts', data)
            })
        },
        import(store) {
            store.state.importInfo.importing = true
            store.state.importInfo.error = false

            const page = store.state.importInfo.page
            const toImport = store.state.importData.contacts
                .slice((page - 1) * store.state.importInfo.perPage, page * store.state.importInfo.perPage)
                .map((contact) => contact.values)

            api.post('contact-import/' + store.state.importData.id, {contacts: toImport}).then(() => {
                if (page < store.state.importInfo.pages) {
                    store.state.importInfo.page += 1
                    store.dispatch('import')
                } else {
                    store.state.importInfo.importedFinished = true
                    store.commit('contactList/resetList', null, {root: true})
                }
            }).catch(() => {
                store.state.importInfo.importedFinished = false
                store.state.importInfo.error = true
            })
        },
        removeImport(store, importId) {
            return api.deleteByUrl(store, 'contact-import/' + importId, 'Import contacts')
        },
        duplicity(store, contact) {
            return api.post(baseUrl + '/duplicity', {
                name: contact.name || null,
                email: contact.email || null,
                phone: contact.phone || null,
                contactId: contact.id || null,
            })
        }
    },
    mutations: {
        setNetwork(state, data) {
            state.network.links = [];
            state.network.nodes = [];
            data.nodes.forEach((item) => {
                state.network.nodes.push({...item, svgSym: nodeIcon('ss')})
            });
            state.network.links = data.links;
        },
        setContacts(state, data) {
            state.contacts = [...state.contacts, ...data];
        },
        setContactsPaginator(state, paginator) {
            state.contactPaginator = paginator;
            state.contactPaginator.pages = paginator.pages === 0 ? 1 : paginator.pages;
        },
        resetContacts(state) {
            state.contacts = [];
        },
        setContact(state, data) {
            state.contact = {...defaultContact, ...data};
        },
        resetContact(state) {
            state.contact = {...defaultContact};
        },
        setContactParent(state, parent) {
            state.contact.parent = parent;
        },
        setLoading(state, status) {
            state.loading = status;
        },
        setImportContacts(state, data) {
            state.importData = data
            state.importInfo.contactCount = state.importData.contacts.length
            state.importInfo.page = 1
            state.importInfo.pages = Math.ceil(state.importInfo.contactCount / state.importInfo.perPage);
            state.importInfo.importing = false
            state.importInfo.importedFinished = false
            state.importInfo.error = false
        },
        resetImport(state) {
            state.importData = null
            state.importInfo.contactCount = 0
            state.importInfo.page = 1
            state.importInfo.pages = 0;
            state.importInfo.importing = false
            state.importInfo.importedFinished = false
            state.importInfo.error = false
        },
    }
}
