import api, {defaultPaginator} from '../api';

const baseUrl = 'tutorial'

export default {
    namespaced: true,
    state: {
        tutorials: {
            loading: false,
            items: [],
            paginator: {...defaultPaginator}
        }
    },
    getters: {},
    actions: {
        list(store, params) {
            store.state.tutorials.loading = true

            return api.list(store, baseUrl + '/list', params, ({data}) => {
                store.state.tutorials = {
                    loading: false,
                    ...data
                }
            })
        },
        played(store, id) {
            return api.postItem(store, baseUrl + '/played/' + id, {}, null)
        }
    },
    mutations: {

    }
}
