import api from '../api';

const isOpenedKanban = (contact) => {
    return contact.closedSuccessfully === true || contact.closedAt === null;
}

export default {
    namespaced: true,
    state: {
        loading: true,
        kanban: null,
        disabledSteps: JSON.parse(localStorage.getItem('disabledSteps') || '[]'),
    },
    getters: {
        calendarEvents(state) {
            return (id) => {
                return [] // todo:
                return state.kanban.calendarEvents[id] ?? []
            };
        },
    },
    actions: {
        definition(store, id) {
            store.commit('setLoading', true)
            return api.getItem(store, 'kanban/definition/' + id, () => {
                store.commit('setLoading', false)
            })
        },
        get(store, data) {
            const userId = data.userId === null ? '' : '?userId=' + data.userId;
            store.commit('setLoading', true)
            return api.getItem(store, 'kanban/' + data.id + userId, ({data}) => { // ok
                store.commit('setKanban', data)
                store.commit('setLoading', false)
            })
        },
        name(store, id) {
            return api.getItem(store, 'kanban/name/' + id)
        },
    },
    mutations: {
        setLoading(state, status) {
            state.loading = status
        },
        setKanban(state, data) {
            state.kanban = data
        },
        toggleStep(state, stepId) {
            const index = state.disabledSteps.indexOf(stepId);
            if (index > -1) {
                state.disabledSteps.splice(index, 1);
            } else {
                state.disabledSteps.push(stepId)
            }
            localStorage.setItem('disabledSteps', JSON.stringify(state.disabledSteps))
        },
    }
}
