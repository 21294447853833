import Vue from "vue";
import UsersSelect from "@/crm/UsersSelect";
import TreeSelect from "@/crm/TreeSelect";
import Gallery from "@/crm/Gallery";
import Autocomplete from "@/crm/Autocomplete";
import ContactAutocomplete from "@/crm/ContactAutocomplete";
import TagInput from "@/crm/TagInput";
import CityInput from "@/crm/CityInput";
import NativeDate from "@/crm/NativeDate";
import NativeSelect from "@/crm/NativeSelect";
import NativeTime from "@/crm/NativeTime";

Vue.component("field-usersSelect", UsersSelect);
Vue.component("field-treeSelect", TreeSelect);
Vue.component("field-gallery", Gallery);
Vue.component("field-autocomplete", Autocomplete);
Vue.component("field-contactAutocomplete", ContactAutocomplete);
Vue.component("field-tagInput", TagInput);
Vue.component("field-cityInput", CityInput);
Vue.component("field-nativeDate", NativeDate);
Vue.component("field-nativeSelect", NativeSelect);
Vue.component("field-nativeTime", NativeTime);