import api, {csrfKey} from '../api';

const baseUrl = 'account'

export default {
    namespaced: true,
    state: {
        user: null,
    },
    getters: {
        account(state, getters) {
            return state.user;
        },
        hasPermission(state) {
            return function (slug, type) {
                if (state.user === null || state.user.userAcl === undefined) {
                    return false;
                }
                const found = state.user.userAcl.find((item) => {
                    return item.acl.slug === slug;
                });
                return found !== undefined && found.value.includes(type);
            }
        },
        hasOutlook(state) {
            return state.user === null ? null : state.user.hasCalendar
        }
    },
    actions: {
        login(store, params) {
            return api.postItem(store, baseUrl + '/login', params, null, (response) => {
                localStorage.setItem(csrfKey, response.data.status);
            })
        },
        get(store) {
            return api.getItem(store, baseUrl, ({data}) => { // ok
                store.commit('setVersion', data.version)

                if (data.loggedIn === true) {
                    store.commit('setProfile', data.account);
                } else if (window.location.pathname.startsWith('/public') === false) {
                    store.dispatch('alert/warning', 'Account not exists or login token expired', {root: true});
                }
            })
        },
        stats(store) {
            return api.getItem(store, baseUrl + '/stats')
        },
        logout(store) {
            return api.postItem(store, baseUrl + '/logout', {}, null, () => {
                localStorage.removeItem(csrfKey);
                store.commit('setProfile', null);
            })
        },
        changePassword(store, params) {
            return api.editItem(store, baseUrl + '/change-password', params, 'Password was changed')
        },
        updateProfile(store, params) {
            return api.editItem(store, baseUrl + '/update-profile', params, 'Profile was updated', () => {
                store.dispatch('get');
            })
        },
        lostPassword(store, params) {
            return api.postItem(store, baseUrl + '/lost-password', params, 'Please check your e-mail address')
        },
        validateToken(store, params) {
            return api.get(baseUrl + '/get-lost-password/' + params.id + '/' + params.hash);
        },
        resetPassword(store, params) {
            return api.editItem(
                store,
                baseUrl + '/reset-password/' + params.id + '/' + params.hash,
                params,
                'Password changed, please login'
            )
        },
        uploadAvatar(store, data) {
            return new Promise((resolve, reject) => {
                api.editItem(store, 'account/avatar', data, 'Avatar was updated!', () => {
                    store.dispatch('get').then(() => {
                        resolve()
                    })
                }).catch(() => {
                    reject()
                })
            })
        }
    },
    mutations: {
        setProfile(state, data) {
            state.user = data;
        },
        setVersion(state, version) {
            const currentVersion = localStorage.getItem('a2hsVersion')
            if (currentVersion === null || version !== parseInt(currentVersion)) {
                localStorage.setItem('a2hsVersion', version);
                if (currentVersion !== null) {
                    window.location.reload();
                }
            }
        },
    }
}
