import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import account from './modules/account';
import contact from './modules/contact';
import status from './modules/status';
import codeBook from './modules/codeBook';
import user from './modules/user';
import acl from './modules/acl';
import callparty from "@/store/modules/callparty";
import contactKanban from "@/store/modules/contactKanban";
import kanban from "@/store/modules/kanban";
import report from "@/store/modules/report";
import goal from "@/store/modules/goal";
import milestone from "@/store/modules/milestone";
import seminar from "@/store/modules/seminar";
import gallery from "@/store/modules/gallery";
import callList from "@/store/modules/callList";
import structure from "@/store/modules/structure";
import meeting from "@/store/modules/meeting";
import kanbanStepFailStatus from "@/store/modules/kanbanStepFailStatus";
import metric from "@/store/modules/metric";
import contactNote from "@/store/modules/contactNote";
import callScript from "@/store/modules/callScript";
import structureType from "@/store/modules/structureType";
import group from "@/store/modules/group";
import systemElementsAttendance from "@/store/modules/systemElementsAttendance";
import systemElement from "@/store/modules/systemElement";
import contactList from "@/store/modules/contactList";
import calendar from "@/store/modules/calendar";
import contactKanbanPerformance from "@/store/modules/contactKanbanPerformance";
import contactKanbanRecommendation from "@/store/modules/contactKanbanRecommendation";
import contactReminder from "@/store/modules/contactReminder";
import contactRecommendations from "@/store/modules/contactRecommendations";
import serviceList from "@/store/modules/serviceList";
import tutorial from "@/store/modules/tutorial";
import ranking from "@/store/modules/ranking";
import notes from "@/store/modules/notes";
import kanbanCalendar from "@/store/modules/kanbanCalendar";
import contactKanbanGoal from "@/store/modules/contactKanbanGoal";
import version from "@/store/modules/version";

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        account,
        contact,
        contactList,
        contactReminder,
        contactRecommendations,
        status,
        codeBook,
        user,
        acl,
        callparty,
        callList,
        kanbanCalendar,
        contactKanban,
        contactKanbanGoal,
        contactKanbanPerformance,
        contactKanbanRecommendation,
        kanban,
        report,
        goal,
        milestone,
        seminar,
        gallery,
        structure,
        meeting,
        kanbanStepFailStatus,
        metric,
        contactNote,
        callScript,
        structureType,
        group,
        systemElementsAttendance,
        systemElement,
        calendar,
        serviceList,
        tutorial,
        ranking,
        notes,
        version,
    }
});

export const availableMonths = () => {
    const currentYear = (new Date()).getFullYear()
    const currentMonth = (new Date()).getMonth() + 1;
    const out = [];
    for (let year = 2021; year <= currentYear; year++) {
        for (let month = 1; month <= 12; month++) {
            if ((year === 2021 && month < 4) || (year === currentYear && month > currentMonth)) {
                continue;
            }
            const name = month + '/' + year;
            out.push({
                id: name,
                month: month,
                year: year,
            })
        }
    }
    return out;
}
