import api from './../api';


export const treeSelect = (data, out = []) => {
    data.forEach((item) => {
        let user = {
            id: item.id,
            label: item.fullName,
        }
        const children = treeSelect(item.user);
        if (children.length !== 0) { // inserted same user that's why 1
            const parentUser = {...user}
            user.id = user.id + '-parent'
            user.children = [...[parentUser], ...children];
        }
        out.push(user)
    })
    return out;
}

export const treeSelectNew = (data, out = []) => {
    data.forEach((item) => {
        let user = {
            id: item.id,
            label: item.name,
        }
        const children = treeSelectNew(item.children);
        if (children.length !== 0) { // inserted same user that's why 1
            const parentUser = {...user}
            user.id = user.id + '-parent'
            user.children = [...[parentUser], ...children];
        }
        out.push(user)
    })
    return out;
}

export default {
    namespaced: true,
    state: {
        codeBooks: {}
    },
    getters: {
        get(state) {
            return function (codeBook) {
                return state.codeBooks[codeBook] === undefined ? [] : state.codeBooks[codeBook];
            };
        }
    },
    actions: {
        kanbanStep(store, kanbanId) {
            return api.getItem(store, 'code-book/kanban-step/' + kanbanId)
        },
        kanbanStepFailStatus(store, kanbanId) {
            return api.getItem(store, 'code-book/kanban-step-fail-status/' + kanbanId)
        },
        structure(store, params) {
            return new Promise((resolve, reject) => {
                api.get('code-book/structure/' + params.month + '/' + params.year + '/' + params.type).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    // todo: catch error :)
                    reject(error.response);
                });
            });
        },
        structureTree(store, data) {
            const month = data.month || (new Date()).getMonth() + 1
            const year = data.year || (new Date()).getFullYear()
            const type = data.type || 'report'
            return api.getItem(store, 'code-book/structure-tree/' + month + '/' + year + '/' + type)
        },
        load(store, codebooks) {
            return new Promise((resolve, reject) => {
                api.get('code-book/list/' + codebooks).then(response => {
                    store.commit('setCodeBooks', response.data);
                    resolve(response.data);
                }).catch(error => {
                    // todo: catch error :)
                    reject(error.response);
                });
            });
        },
        city(store, search) {
            return api.search(store, 'code-book/city', search)
        },
        contact(store, search) {
            return api.search(store, 'code-book/contact', search)
        },
        tag(store, search) {
            return api.search(store, 'code-book/tag', search)
        },
    },
    mutations: {
        setCodeBooks(state, data) {
            Object.keys(data).forEach(item => {
                state.codeBooks[item] = data[item];
            });
        }
    }
}
