import api, {defaultPaginator} from '../api';

const baseUrl = 'contact-reminder'

export default {
    namespaced: true,
    state: {
        reminders: {
            loading: false,
            items: [],
            paginator: {...defaultPaginator},
        },
    },
    actions: {
        create(store, data) {
            return api.postItem(store, baseUrl, data, 'Reminder was created!')
        },
        update(store, data) {
            return api.editItem(store, baseUrl + '/' + data.id, data, 'Reminder was updated!', (response) => {
                store.commit('updateReminder', response.data)
            })
        },
        contact(store, data) {
            return api.getItem(store, baseUrl + '/contact/' + data.id + '/' + data.tab)
        },
        getById(store, id) {
            return api.getItem(store, baseUrl + '/' + id)
        },
        count(store) {
            return api.getItem(store, baseUrl + '/count')
        },
        events(store, contactKanbanId) {
            return api.getItem(store, baseUrl + '/events/' + contactKanbanId)
        },
        load(store, data) {
            store.state.reminders.loading = true

            if (data.page === 1) {
                store.state.reminders.items = []
            }

            api.listData(store, baseUrl + '/list/' + data.page + '/20', data).then((data) => {
                store.commit('setReminders', data)
            })
        },
        resolve(store, id) {
            return api.editItem(store, baseUrl + '/resolve/' + id, {}, 'Contact reminder resolved', () => {
                store.commit('removeReminder', id)
            })
        },
        unresolve(store, id) {
            return api.editItem(store, baseUrl + '/un-resolve/' + id, {}, 'Contact reminder renewed', () => {
                store.commit('removeReminder', id)
            })
        }
    },
    mutations: {
        open(state, id) {
            state.openedId = state.openedId === id ? null : id
        },
        setReminders(state, data) {
            state.reminders.items = data.paginator.page === 1 ? data.items : [...state.reminders.items, ...data.items]
            state.reminders.paginator = data.paginator
            state.reminders.loading = false
        },
        removeReminder(state, id) {
            state.reminders.items = state.reminders.items.filter((item) => {
                return item.id !== id
            })
        },
        updateReminder(state, data) {
            const found = state.reminders.items.findIndex((item) => {
                return item.id === data.id
            })

            if (found !== -1) {
                const reminders = state.reminders.items;
                reminders[found] = data
                state.reminders.items = [...reminders]
            }
        }
    }
}
