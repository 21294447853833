import api from '../api';

export default {
    namespaced: true,
    state: {
        items: [],
    },
    getters: {},
    actions: {
        list(store) {
            api.get('code-book/list/status').then(response => {
                store.commit('setItems', response.data.status);
            }).catch(error => {
                // todo: wtf?
            });
        },

    },
    mutations: {
        setItems(state, data) {
            state.items = data;
        },
    }
}
