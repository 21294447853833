<template>
    <div class="quick-search" data-cy="quick-search">
        <div class="quick-search-loading-wrapper" v-if="loading">
            <titan-loading size="xs"/>
        </div>
        <input type="text"
               v-model="valueProxy"
               class="form-control"
               :placeholder="placeholder"
               :disabled="disabled"
               @focus="setBoxView(true)"
               @blur="setBoxView(false)"
               @keyup.esc="removeSearch(true)"
               @keyup.enter="addItemByIndex(activeIndex)"
               @keyup.down="selectNext($event)"
               @keyup.up="selectPrev($event)"
               @keyup="searchItem($event)"
               @keydown.enter.prevent
               @keydown.esc.prevent
               @keydown.down.prevent
               @keydown.up.prevent
               autocomplete="off"
               data-cy="search-input"
        >
        <div class="search-box" v-if="showBox === true" data-cy="result-wrapper">
            <div class="search-box-info"
                 data-cy="no-results"
                 v-if="searchResults.length === 0 && valueProxy.length >= minStringLength && loading === false"
            >
                {{ i18n('No results found') }}
            </div>
            <div class="search-box-info" v-if="valueProxy.length < minStringLength" data-cy="warning">
                {{ i18n('Type at least {count} chars').replace('{count}', minStringLength) }}
            </div>
            <ul v-if="searchResults.length !== 0" data-cy="results">
                <li v-for="(item, index) in searchResults"
                    @mouseover="activeIndex = index"
                    @mouseleave="activeIndex = null"
                    @click="addItemByIndex(index)"
                    :class="{'active': index === activeIndex}"
                >
                    <div v-html="getName(item)" data-cy="result-item"></div>
                </li>

            </ul>
        </div>
    </div>
</template>
<script>
import {abstractField} from "vue-form-generator"
import {TitanLoading} from "vue-titan-elements"
import FormMixin from "vue-titan-form/src/form/FormMixin";

export default {
    components: {
        TitanLoading,
    },
    mixins: [abstractField, FormMixin],
    computed: {
        placeholder() {
            return this.schema.placeholder === undefined ? '' : this.schema.placeholder;
        },
        values() {
            return this.schema.values === undefined ? null : this.schema.values;
        },
        selected() {
            return this.schema.selected === undefined ? this.valueLabel : this.schema.selected;
        },
        valueLabel() {
            return this.schema.valueLabel === undefined ? 'name' : this.schema.valueLabel;
        },
        minStringLength() {
            return this.schema.minStringLength === undefined ? 2 : this.schema.minStringLength;
        },
    },
    mounted() {
        this.validateValue();
        this.valueProxy = this.value === null ? '' : this.value[this.valueLabel]
    },
    methods: {
        validateValue() {
            if (this.valueProxy === null || this.valueProxy === undefined) {
                this.valueProxy = '';
            }
        },
        setBoxView(type) {
            setTimeout(() => {
                if (type === false && (this.value === null || this.valueProxy !== this.value[this.valueLabel])) {
                    this.valueProxy = ''
                }
                this.showBox = type;
                this.validateValue();
                if (type === true && this.valueProxy.length > this.minStringLength) {
                    this.searchItem()
                }
            }, 200);
        },
        selectNext(event) {
            event.preventDefault();
            this.activeIndex = this.activeIndex === null || this.activeIndex >= (this.searchResults.length - 1)
                ? 0
                : this.activeIndex + 1;
        },
        selectPrev(event) {
            event.preventDefault();
            const lastIndex = this.searchResults.length - 1;
            this.activeIndex = this.activeIndex === null || this.activeIndex === 0
                ? lastIndex
                : this.activeIndex - 1;
        },
        searchItem(event) {
            if (event === undefined
                || (event.key !== 'ArrowDown' && event.key !== 'ArrowUp' && event.key !== 'Enter')
            ) {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.callApi();
                }, 300);
            }
        },
        callApi() {
            if (this.valueProxy.trim().length >= this.minStringLength && this.values !== null) {
                this.loading = true;
                this.values(this.valueProxy).then((data) => {
                    this.setData(data);
                }).catch(() => {
                    this.setData([]);
                });
            } else {
                this.setData([]);
            }
        },
        setData(data) {
            if (this.searchResults.length - 1 < this.activeIndex) {
                this.activeIndex = null;
            }
            this.searchResults = data;
            this.loading = false;
        },
        addItemByIndex(index) {
            const item = this.searchResults[index];
            if (item !== undefined) {
                this.valueProxy = item[this.valueLabel];
                this.value = item
                this.removeSearch();
            }
        },
        getName(result) {
            return typeof this.valueLabel === 'function' ? this.valueLabel(result) : result[this.valueLabel];
        },
        removeSearch(showBox = false) {
            this.setBoxView(showBox)
            this.activeIndex = null;
            this.loading = false;
            this.searchResults = [];
        },
    },
    data() {
        return {
            valueProxy: '',
            showBox: false,
            activeIndex: null,
            loading: false,
            searchResults: [],
            timeout: null,
        }
    }
};
</script>
<style lang="scss">
.quick-search {
    position: relative;
    width: 100%;

    .quick-search-loading-wrapper {
        position: absolute;
        top: 2px;
        right: 0;
    }

    .form-control {
        outline: none;
        width: 100%;
    }

    .data-loader {
        position: absolute;
        top: 10px;
        right: 25px;
    }

    .search-box {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: #fff;
        border: solid #eee;
        border-width: 0 1px 1px 1px;
        border-radius: 5px 5px 5px 5px;

        -webkit-box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.15);
        box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.15);
        z-index: 10;
        padding: 0;
        margin: 0;

        .search-box-info {
            padding: 13px;
            font-size: 0.9rem;
        }

        > ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                padding: 8px 13px;
                display: block;
                color: #000;
                text-decoration: none;
                cursor: pointer;
                font-size: .95rem;
                line-height: 1.4;
                border-bottom: 1px solid #eee;

                &.active {
                    background: #009DFA;
                    color: #fff;
                    border-color: #009DFA;
                }

                &:first-child {
                    border-radius: 5px 5px 0 0;
                }

                &:last-child {
                    border-radius: 0 0 5px 5px;
                }
            }
        }
    }
}
</style>