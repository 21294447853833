import api from '../api';

const baseUrl = 'calendar'

export default {
    namespaced: true,
    state: {
        events: []
    },
    actions: {
        events(store, filter) {
            const query = filter.userId === null ? '' : '?userId=' + filter.userId
            return api.getItem(store, baseUrl + '/events/' + filter.start + '/' + filter.end + query);
        },
        contactKanban(store, id) {
            return api.getItem(store, baseUrl + '/contact-kanban/' + id);
        },
        getById(store, params) {
            const query = params.userId === undefined || params.userId === null ? '' : '?userId=' + params.userId
            return api.getItem(store, baseUrl + '/' + params.id + query);
        },
        remove(store, id) {
            return api.deleteById(store, baseUrl, id)
        },
        create(store, data) {
            return api.postItem(store, baseUrl, data, 'Event was created!')
        },
        download(store, id) {
            return api.editItem(store, baseUrl + '/download/' + id, {}, 'Event was downloaded')
        },
        edit(store, data) {
            return api.editItem(store, baseUrl + '/' + data.id, data, 'Event was updated!')
        },
        move(store, data) {
            return api.editItem(store, baseUrl + '/move/' + data.id, data, 'Event was moved!')
        },
        connectKanban(store, data) {
            return api.editItem(store, baseUrl + '/connect-kanban/' + data.eventId, data, 'Event was connected with kanban!')
        },
        unAuthorize(store) {
            return api.editItem(store, baseUrl + '/un-auth', {}, 'Outlook was un authorized', () => {
                store.dispatch('account/get', {root: true})
            })
        }
    },
    mutations: {

    }
}

export const setView = (view) => {
    localStorage.setItem('calendarView', view)
}

export const getView = () => {
    const view = localStorage.getItem('calendarView');
    return view === null ? 'dayGridMonth' : view;
}

export const normalizeEvents = (data) => {
    const events = []
    data.forEach((item) => {
        const color = item.calendarEvent === null ? 'silver' : item.calendarEvent.color

        events.push({
            ...item,
            title: item.subject,
            allDay: item.isAllDay,
            display: item.type === 'background' ? 'background' : 'auto',
            classNames: [
                item.type + '-wrapper',
                'color-' + color
            ]
        })
    })

    return events
}