import api, {defaultPaginator} from '../api';

const baseUrl = 'note'

export default {
    namespaced: true,
    state: {
        noteList: {
            loading: false,
            items: [],
            paginator: {...defaultPaginator},
        },
        notesData: {
            notes: [],
            users: {}
        },
    },
    actions: {
        getById(store, id) {
            return api.getItem(store, baseUrl + '/' + id);
        },
        load(store, data) {
            store.commit('setListLoading')
            api.listData(store, baseUrl + '/list/' + data.page + '/20', data).then((data) => {
                store.commit('setList', data)
            }).catch()
        },
        create(store, data) {
            return api.postItem(store, baseUrl, data, 'Note was created')
        },
        update(store, data) {
            return api.editItem(store, baseUrl + '/' + data.id, data, 'Note was updated')
        },
        contactNotes(store, params) {
            store.commit('setNotes');
            const query = params.contactKanbanId === null ? '' : '?contactKanbanId=' + params.contactKanbanId
            api.get(baseUrl + '/contact/' + params.id + query).then((res) => {
                store.commit('setNotes', res.data);
            });
        },
    },
    mutations: {
        openNote(state, {note}) {
            if (note.excalidraw === true) {
                const currentUrl = encodeURIComponent(window.location.href)
                window.location.href = process.env.VUE_APP_EXCALIDRAW + note.id + '?url=' + currentUrl
            }
        },
        setListLoading(state) {
            state.noteList.loading = true
            state.noteList.items = []
        },
        setList(state, data) {
            state.noteList.items = data.items
            state.noteList.paginator = data.paginator
            state.noteList.loading = false
        },
        setNotes(state, data) {
            if (data === undefined) {
                state.notesData.notes = []
                state.notesData.users = {}
            } else {
                state.notesData.notes = data.notes;
                data.users.forEach((user) => {
                    state.notesData.users[user.id] = user;
                })
            }
        },
    }
}
