import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import VueChart from 'vue-chart-js'
import TitanElements from "vue-titan-elements";
import Translator from 'vue-titan-translator'
import {DropdownPlugin} from 'bootstrap-vue'
import {apiLink} from "@/store/api";
import {VBTooltipPlugin} from 'bootstrap-vue'
import vClickOutside from 'v-click-outside'
import Vue2TouchEvents from 'vue2-touch-events'
import messages from './locales.json'
import './fontawesome'
import './form-fields'
import './filters'
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";

Vue.use(Vue2TouchEvents)
Vue.use(vClickOutside)
Vue.use(VBTooltipPlugin)
Vue.use(VueChart)
Vue.use(DropdownPlugin)
Vue.use(TitanElements, {store})

Vue.config.productionTip = false;

const translatorMessages = () => {
    return process.env.VUE_APP_DB_TRANSLATES === '1' ? {} : messages
}

Translator.install(Vue, {
    store,
    apiUrl: apiLink(),
    flagPath: '/flags/4x3/{lang}.svg',
    languages: {
        ADM: [
            {
                key: 'sk',
                name: 'Slovenčina',
                default: true,
            }, {
                key: 'gb',
                name: 'English',
            }
        ]
    },
    type: 'ADM',
    messages: translatorMessages(),
})

if (process.env.VUE_APP_SENTRY_ENV !== 'development') {
    Sentry.init({
        Vue,
        dsn: "https://a2c698595a124651b068188296457bb8@o198312.ingest.sentry.io/4504452099801088",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: [
                    "http://planyaktivity.lcl:8080",
                    "https://staging.planyaktivity.sk",
                    'https://planyaktivity.sk',
                    /^\//
                ],
            }),
        ],
        ignoreErrors: [
            "ChunkLoadError",
            // "Non-Error exception captured",
            // "Non-Error promise rejection captured"
        ],
        environment: process.env.VUE_APP_SENTRY_ENV,
        tracesSampleRate: 0.02,
    });
}

export const formatNumber = (number, minimumFractionDigits = 2) => {
    return Number(number).toLocaleString("sk-SK", {
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: minimumFractionDigits
    });
};

new Vue({
    router,
    store,
    beforeMount() {
        if (process.env.VUE_APP_SENTRY_ENV !== 'development') {

        }
    },
    render: h => h(App)
}).$mount('#app');
