<template>
    <div class="tags-input">
        <vue-tags-input
            v-model="city"
            :tags="value"
            :autocomplete-items="autocompleteItems"
            :add-only-from-autocomplete="true"
            @tags-changed="update"
            :placeholder="t('Add tag')"
            :max-tags="limit"
        />
    </div>
</template>

<script>
import {abstractField} from 'vue-form-generator'
import FormMixin from "vue-titan-form/src/form/FormMixin";
import VueTagsInput from '@johmun/vue-tags-input';

export default {
    name: "TagInput",
    mixins: [abstractField, FormMixin],
    components: {
        VueTagsInput,
    },
    computed: {
        limit() {
            return this.schema.limit || 50
        }
    },
    watch: {
        'city': 'initItems',
    },
    methods: {
        update(newTags) {
            this.autocompleteItems = [];

            this.value = newTags.map(item => {
                return {
                    id: item.id || null,
                    text: item.text
                }
            })
        },
        initItems() {
            if (this.city.length < 2) return;

            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.$store.dispatch('codeBook/city', this.city).then((response) => {
                    this.autocompleteItems = response.map(a => {
                        return {
                            id: a.id,
                            text: a.text
                        }
                    })
                })
            }, 600);
        },

    },
    data() {
        return {
            city: '',
            autocompleteItems: [],
            debounce: null,
        };
    },
}
</script>

<style lang="scss" scoped>
.tags-input {
    width: 100%;

    ::v-deep {
        .vue-tags-input {
            max-width: 100%;
            width: 100%;

            .ti-input {
                border-radius: 0.25rem;
                border: 1px solid #ced4da;
            }

            &.ti-focus .ti-input {
                border-color: #7bceff;
            }
        }
    }
}
</style>