import axios from 'axios/index';
import router from '../router/router'

export const apiLink = () => {
    return process.env.VUE_APP_API_LINK || (location.protocol + '//' + location.hostname + '/api/');
};

export const csrfKey = process.env.VUE_APP_API_CSRF || 'csrf';


export const defaultPaginator = {
    page: 1,
    perPage: 10,
    pages: 0,
    items: 0,
}

export default {
    getAxiosConfig() {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem(csrfKey);
        return {
            withCredentials: true,
            params: {}
        };
    },

    get(link) {
        return axios.get(apiLink() + link, this.getAxiosConfig());
    },

    post(link, data = {}) {
        return axios.post(apiLink() + link, data, this.getAxiosConfig());
    },

    put(link, data = {}) {
        return axios.put(apiLink() + link, data, this.getAxiosConfig());
    },

    delete(link) {
        return axios.delete(apiLink() + link, this.getAxiosConfig());
    },

    processError(store, error, reject, errorMessage = null, catchError = false) {
        if (error.response === undefined || error.response.status === undefined) {
            throw error
        } else {
            const responseStatus = error.response?.status ?? 0

            // System errors
            const systemError = [500, 501].includes(responseStatus)

            if (errorMessage === null || systemError === true) {
                store.dispatch('alert/error', error.response.data.status, {root: true});
            } else if (errorMessage !== false) {
                store.dispatch('alert/error', errorMessage, {root: true});
            }

            if (catchError === true && systemError === false) {
                reject(error.response)
            } else {
                // User errors
                const loginMessage = [
                    'Token is not available, login in',
                    'You are not logged in',
                    'Account not exists or login token expired',
                ]

                if (error.response.data
                    && [401, 403].includes(responseStatus)
                    && loginMessage.includes(error.response.data.status)
                ) {
                    const loginPage = router.resolve({name: 'login'})
                    window.location.href = loginPage.href
                }
            }
        }
    },

    list(store, url, data, successCallBack = null) {
        const page = data.page || 1
        const perPage = data.perPage || 10
        const fullUrl = url + '/' + page + '/' + perPage
        return this.listData(store, fullUrl, data, successCallBack)
    },

    listData(store, url, data, successCallBack = null) {
        return this.postItem(store, url, data, null, successCallBack)
    },

    deleteById(store, url, id) {
        return this.deleteByUrl(store, url + '/' + id, url + ' was deleted', url + ' was not deleted')
    },

    create(store, url, data) {
        return this.postItem(store, url, data, url + ' was created!')
    },

    edit(store, url, data) {
        return this.editItem(store, url + '/' + data.id, data, url + ' was updated!', (response) => {
            store.commit('setItem', response.data)
        })
    },

    deleteByUrl(store, url, messageName = null, errorMessage = null, catchError = false) {
        return new Promise((resolve, reject) => {
            this.delete(url).then(() => {
                resolve();
                if (messageName !== null) {
                    store.dispatch('alert/success', messageName, {root: true});
                }
            }).catch((error) => this.processError(store, error, reject, errorMessage, catchError))
        });
    },

    getItem(store, url, successCallBack = null, errorMessage = null, catchError = false) {
        return new Promise((resolve, reject) => {
            this.get(url).then((response) => {
                if (successCallBack !== null) {
                    successCallBack(response)
                }

                resolve(response.data)
            }).catch((error) => this.processError(store, error, reject, errorMessage, catchError))
        });
    },

    postItem(store, url, data = {}, messageName = null, successCallBack = null, errorMessage = null, catchError = false) {
        return new Promise((resolve, reject) => {
            this.post(url, data).then((response) => {
                if (messageName !== null) {
                    store.dispatch('alert/success', messageName, {root: true});
                }
                if (successCallBack !== null) {
                    successCallBack(response)
                }
                resolve(response.data)
            }).catch((error) => this.processError(store, error, reject, errorMessage, catchError))
        })
    },

    editItem(store, url, data, messageName = null, successCallBack = null, errorMessage = null, catchError = false) {
        return new Promise((resolve, reject) => {
            this.put(url, data).then((response) => {
                if (messageName !== null) {
                    store.dispatch('alert/success', messageName, {root: true});
                }
                if (successCallBack !== null) {
                    successCallBack(response)
                }
                resolve(response.data)
            }).catch((error) => this.processError(store, error, reject, errorMessage, catchError))
        })
    },

    search(store, url, string) {
        return new Promise((resolve, reject) => {
            if (string.length < 2) {
                resolve([])
            } else {
                this.post(url + '?search=' + encodeURIComponent(string), {}).then((response) => {
                    resolve(response.data)
                }).catch((error) => this.processError(store, error, reject))
            }
        })
    },

    upload(store, url, file, callback = null) {
        const formData = new FormData();
        formData.append('file', file);

        const config = {...this.getAxiosConfig(), 'Content-Type': 'multipart/form-data'};

        return new Promise((resolve, reject) => {
            axios.post(apiLink() + url, formData, config).then((response) => {
                if (callback !== null) {
                    callback(response.data)
                }
                // store.dispatch('alert/success', url + ' was uploaded!', {root: true});
                resolve(response.data)
            }).catch((error) => this.processError(store, error, reject))
        })
    },
}

export const getBase64 = (store, file) => {
    return new Promise((resolve, reject) => {
        if (['image/jpeg', 'image/png'].includes(file.type) === false) {
            store.dispatch('alert/error', 'Unsupported image format')
            reject();
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        }
    });
}
