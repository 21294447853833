import api, {defaultPaginator} from '../api';

const baseUrl = 'ranking'

export default {
    namespaced: true,
    state: {
        users: {
            loading: false,
            paginator: {...defaultPaginator},
            items: [],
            closed: null,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
        },
        contacts: {
            loading: false,
            paginator: {...defaultPaginator},
            items: [],
        }
    },
    getters: {},
    actions: {
        edit(store, data) {
            return api.editItem(store, baseUrl + '/' + data.id, data, 'Ranking was updated')
        },
        close(store, data) {
            return api.editItem(store, baseUrl + '/close', data, 'Ranking was closed', () => {
                store.state.users.closed = true
            })
        },
        open(store, data) {
            return api.editItem(store, baseUrl + '/open', data, 'Ranking was opened', () => {
                store.state.users.closed = false
            })
        },
        create(store, data) {
            return api.postItem(store, baseUrl, data, 'Ranking was created')
        },
        deleteContact(store, id) {
            return api.deleteByUrl(store, baseUrl + '/remove-contact/' + id, 'Ranking contact was deleted')
        },
        import(store, data) {
            return api.postItem(
                store,
                baseUrl + '/import/' + data.userId + '/' + data.month + '/' + data.year,
            )
        },
        validate(store, id) {
            return api.editItem(store, baseUrl + '/validate/' + id, {}, 'Ranking was validated')
        },
        kanbanData(store, id) {
            return api.editItem(store, baseUrl + '/kanban-data/' + id, {}, 'Ranking was updated')
        },
        userList(store, params) {
            store.state.users.loading = true
            params.perPage = 20

            return api.list(store, baseUrl + '/user-list', params, ({data}) => {
                store.state.users = {
                    loading: false,
                    paginator: data.paginator,
                    items: data.paginator.page === 1 ? data.items : [...store.state.users.items, ...data.items],
                    closed: data.closed,
                    year: data.year,
                    month: data.month,
                }
            })
        },
        contactList(store, params) {
            store.state.contacts.loading = true

            return api.list(store, baseUrl + '/list', params, ({data}) => {
                store.state.contacts = {
                    loading: false,
                    paginator: data.paginator,
                    items: data.paginator.page === 1 ? data.items : [...store.state.contacts.items, ...data.items],
                }
            })
        },
    },
    mutations: {

    }
}
