import {
    faCheck,
    faPlus,
    faAngleRight,
    faDotCircle,
    faCopy,
    faEye,
    faTrashAlt,
    faShareAlt,
    faCloudUploadAlt,
    faSpinner,
    faMedal,
    faQuestionCircle,
    faBars,
    faAngleUp,
    faMinus,
    faAngleDown,
    faArrowsAlt,
    faArrowsAltV,
    faExclamation,
    faWallet,
    faUserFriends,
    faUsers,
    faEllipsisV,
    faTachometerAlt,
    faUser,
    faCommentDollar,
    faCalendarAlt,
    faHome,
    faRuler,
    faPhone,
    faRetweet,
    faUserLock,
    faExclamationTriangle,
    faChartLine,
    faCreditCard,
    faUserShield,
    faUtensils,
    faTrophy,
    faEyeSlash,
    faBullseye,
    faFileAlt,
    faStar,
    faMinusCircle,
    faAddressBook,
    faUserGraduate,
    faCodeBranch,
    faUserCircle,
    faSignOutAlt,
    faGripHorizontal,
    faGripVertical,
    faArchive,
    faExpandArrowsAlt,
    faFlag,
    faCoffee,
    faPen,
    faHandshake,
    faSearch,
    faScroll,
    faCloudDownloadAlt,
    faSave,
    faEdit,
    faCheckCircle,
    faUserAlt,
    faChalkboardTeacher,
    faCircle,
    faArrowUp,
    faArrowDown,
    faThumbsUp,
    faThumbsDown,
    faQuestion,
    faLock,
    faLockOpen,
    faDrawPolygon,
    faClock
} from '@fortawesome/free-solid-svg-icons'

import {faComment} from '@fortawesome/free-regular-svg-icons'

import Vue from "vue";
import {faYoutube, faVimeoV} from '@fortawesome/free-brands-svg-icons'
import {library} from '@fortawesome/fontawesome-svg-core'
import {elementsIcons} from "vue-titan-elements";
import {formElementsIcons} from "vue-titan-form";
import {translatorIcons} from "vue-titan-translator";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

Vue.component("font-awesome-icon", FontAwesomeIcon);

const faIcons = [...elementsIcons, ...formElementsIcons, ...translatorIcons]
faIcons.forEach((icon) => {
    library.add(icon)
});
library.add(faCheck, faAngleUp, faPlus, faAngleRight, faEye, faDotCircle, faCopy, faTrashAlt, faShareAlt, faCloudUploadAlt, faSpinner, faMedal, faQuestionCircle, faBars, faHome);
library.add(faMinus, faAngleDown, faYoutube, faExclamation, faArrowsAltV, faWallet, faUserFriends, faUsers, faEllipsisV, faTachometerAlt, faUser, faCommentDollar, faCalendarAlt);
library.add(faRuler, faPhone, faRetweet, faUserLock, faVimeoV, faExclamationTriangle, faChartLine, faCreditCard, faUserShield, faUtensils, faTrophy, faEyeSlash, faBullseye);
library.add(faFileAlt, faStar, faMinusCircle, faAddressBook, faUserGraduate, faCodeBranch, faUserCircle, faSignOutAlt, faGripHorizontal, faGripVertical, faArchive, faChalkboardTeacher);
library.add(faExpandArrowsAlt, faFlag, faCoffee, faPen, faHandshake, faSearch, faScroll, faCloudDownloadAlt, faSave, faEdit, faCheckCircle, faComment, faUserAlt, faArrowsAlt, faCircle);
library.add(
    faArrowUp,
    faArrowDown,
    faThumbsUp,
    faThumbsDown,
    faQuestion,
    faLock,
    faLockOpen,
    faDrawPolygon,
    faClock
);
