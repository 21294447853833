import api from './../api';

const baseUrl = 'version';

export default {
    namespaced: true,

    state: {
        latest: null
    },
    getters: {

    },
    actions: {
        latest(store) {
            return api.getItem(store, baseUrl + '/latest', ({data}) => {
                store.commit('setLatest', data);
            });
        },
        seen(store, id) {
            return api.postItem(store, baseUrl + '/seen/' + id)
        }
    },
    mutations: {
        setLatest(state, data) {
            state.latest = data;
        }
    }
}