import api from '../api';

export default {
    namespaced: true,
    state: {},
    actions: {
        list(store) {
            return new Promise((resolve, reject) => {
                api.get('metric/list').then((response) => {
                    resolve(response.data)
                }).catch(() => {
                    reject()
                });
            })
        },
    },
    mutations: {}
}
