import api, {defaultPaginator} from '../api';

const baseUrl = 'call-list'

export default {
    namespaced: true,
    state: {
        callParties: [],
        loading: true,
        contacts: [],
        callList: {
            loading: true,
            contacts: [],
            changedOnCallParty: [],
            readOnly: true,
            userId: null,
            notes: {},
            callParty: null,
            kanbans: [],
            report: {
                average: {},
                aof: 0.0,
                interview: 0.0
            }
        },
        historyList: {
            loading: false,
            items: [],
            count: {},
            paginator: {...defaultPaginator},
        },
    },
    actions: {
        getHistory(store, data) {
            store.state.historyList.items = []
            store.state.historyList.loading = true
            api.listData(store, baseUrl + '/history/' + data.page + '/20', data).then((data) => {
                store.state.historyList = {
                    loading: false,
                    ...data,
                }
            })
        },
        callParties(store, data = {}) {
            const id = data.id !== null && data.id !== undefined ? ('/' + data.id) : '';

            return api.postItem(store, baseUrl + '/call-parties' + id, data, null, ({data}) => {
                store.state.callParties = data
            }, false)
        },
        contacts(store, data) {
            const userId = data.userId ? '/' + data.userId : ''
            store.state.callList.loading = true
            return api.getItem(store, baseUrl + '/contacts/' + data.id + userId, ({data}) => { // ok
                store.state.callList = {
                    ...data,
                    count: Array.isArray(data) ? {} : data,
                    loading: false,
                }
                store.state.contacts = data.contacts
            }, false)
        },
        changeCallParty(store, data) {
            return api.editItem(store, 'call-list/change-call-party/' + data.id, data, null, (response) => {
                store.dispatch('alert/success', response.data.status, {root: true});
            })
        },
        createContact(store, data) {
            return api.postItem(store, baseUrl + '/add-contact', data)
        },
        addMultipleContacts(store, data) {
            return api.postItem(store, baseUrl + '/add-multiple-contacts', data, 'Added successfully to call list');
        },
        delete(store, data) {
            return api.deleteByUrl(
                store,
                'call-list/' + data.contactId + '/' + data.callPartyId + '/' + data.kanbanId,
                'Call list contact was deleted successfully.',
            );
        },
    },
    mutations: {}
}
