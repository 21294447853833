import api from '../api';

const baseUrl = 'system-element';

const defaultListState = {
    paginator: {
        pages: 0,
        page: 1,
        perPage: 0,
        items: 0,
    },
    items: [],
    attendees: {},
}

export default {
    namespaced: true,
    state: {
        list: {...defaultListState},
        loadingList: true,
    },
    actions: {
        timeline(store, data) {
            store.state.loadingList = true
            return api.list(store, baseUrl + '/timeline/' + data.type, data, (response) => {
                store.state.list.paginator = response.data.paginator
                store.state.list.items = [...store.state.list.items, ...response.data.items]
                store.state.list.attendees = {...store.state.list.attendees, ...response.data.attendees}
                store.state.loadingList = false
            });
        },
        create(store, data) {
            return api.postItem(store, baseUrl, data, 'System element was created!')
        },
        update(store, data) {
            return api.editItem(store, baseUrl + '/' + data.id, data.data, 'System element was updated!')
        },
        cancel(store, id) {
            return api.editItem(store, baseUrl + '/cancel/' + id, {}, 'Canceled system element')
        },
        kanban(store, contactKanbanId) {
            return api.getItem(store, baseUrl + '/kanban/' + contactKanbanId);
        },
    },
    mutations: {
        resetList(state) {
            state.list = {...defaultListState}
            state.loadingList = true
        }
    }
}
