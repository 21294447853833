import api from '../api';

const baseUrl = 'structure'

export default {
    namespaced: true,
    state: {
        items: [],
    },
    getters: {},
    actions: {
        get(store, data) {
            const link = baseUrl + '/get/' + data.month + '/' + data.year + '/' + data.type;

            return api.getItem(store, data.userId ? (link + '/' + data.userId) : link, ({data}) => {
                store.commit('setItems', data);
            })
        },
        setParent(store, data) {
            return api.editItem(store, baseUrl + '/set-parent/' + data.id, {parent: data.parent}, 'Structure is updated')
        },
        setPosition(store, data) {
            return api.editItem(store, baseUrl + '/set-position/' + data.id, data, 'Position is updated')
        },
    },
    mutations: {
        setItems(state, data) {
            state.items = data;
        },
    }
}
