import api from '../api';

const baseUrl = 'contact-kanban-goal'

export default {
    namespaced: true,
    state: {

    },
    actions: {
        get(store, contactKanbanId) {
            return api.getItem(store, baseUrl + '/' + contactKanbanId)
        },
        update(store, data) {
            return api.editItem(store, baseUrl + '/' + data.contactKanbanId, data, 'Goal was updated')
        }
    },
    mutations: {

    }
}
