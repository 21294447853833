import api, {defaultPaginator} from '../api';

const baseUrl = 'contact';

const defaultAdvancedSearch = {
    age: [],
    profession: [],
    source: [],
    purpose: [],
    incomeFrom: null,
    incomeTo: null,
    level: null,
    contactTag: [],
    contactTagExclude: [],
    city: [],
    region: [],
    district: [],
    location: 'city',
}


export default {
    namespaced: true,
    state: {
        loading: false,
        scrolledLoading: false,
        selected: [],
        contacts: [],
        paginator: {...defaultPaginator},
        advancedSearch: {...defaultAdvancedSearch},
        contactSearch: '',
        contactListSort: 'createdAt',
        contactListSortDirection: 'DESC',
        requestedHighestPage: 0,
    },
    getters: {},
    actions: {
        init(store) {
            if (store.state.contacts.length === 0) {
                store.dispatch('load', 1)
            }
        },
        advancedSearch(store) {
            store.commit('resetList')
            store.dispatch('load', 1)
        },
        search(store, string) {
            store.state.contactSearch = string
            store.commit('resetList')
            store.dispatch('load', 1)
        },
        sort(store, key) {
            store.commit('setSort', key)
            store.commit('resetList')
            store.dispatch('load', 1)
        },
        load(store, page = 1) {
            if (this.scrolledLoading === true
                || (page > store.state.paginator.pages && page !== 1)
                || page <= store.state.requestedHighestPage
            ) {
                return;
            }

            store.state.requestedHighestPage = page
            store.state.loading = true
            store.dispatch('list', getRequestData(store.state, page)).then((data) => {
                store.commit('setResponse', data)
            })
        },
        list(store, data) {
            return api.list(store, baseUrl + '/list', data)
        },
    },
    mutations: {
        resetList(state) {
            state.contacts = []
            state.contacts.paginator = {...defaultPaginator}
            state.selected = []
            state.requestedHighestPage = 0
        },
        setResponse(state, data) {
            state.paginator = data.paginator
            state.contacts = state.paginator.page === 1 ? data.items : [...state.contacts, ...data.items]
            state.loading = false
            state.scrolledLoading = false
        },
        toggleSelected(state, id) {
            const index = state.selected.indexOf(id)
            index === -1 ? state.selected.push(id) : state.selected.splice(index, 1)
        },
        updateSaved(state, contact) {
            const index = state.contacts.findIndex((listContact) => {
                return contact.id === listContact.id
            })
            if (index !== -1) {
                let contacts = [...state.contacts];
                contacts[index] = contact
                state.contacts = contacts
            }
        },
        resetAdvancedSearch(state) {
            state.advancedSearch = {...defaultAdvancedSearch}
            state.advancedSearch.age = []
            state.advancedSearch.profession = []
            state.advancedSearch.source = []
            state.advancedSearch.purpose = []
            state.advancedSearch.contactTag = []
            state.advancedSearch.contactTagExclude = []
            state.advancedSearch.city = []
            state.advancedSearch.region = []
            state.advancedSearch.district = []
        },
        switchRegionInAdvancedSearch(state) {
            if (state.advancedSearch.location === 'city') {
                state.advancedSearch.region = [];
                state.advancedSearch.district = [];
            } else if (state.advancedSearch.location === 'district') {
                state.advancedSearch.region = [];
                state.advancedSearch.city = [];
            } else if (state.advancedSearch.location === 'region') {
                state.advancedSearch.city = [];
                state.advancedSearch.district = [];
            }
        },
        setSort(state, column) {
            if (state.contactListSort === column) {
                state.contactListSortDirection = state.contactListSortDirection === 'ASC' ? 'DESC' : 'ASC'
            } else {
                state.contactListSort = column
                state.contactListSortDirection = 'DESC'
            }
        }
    }
}

const sortPairs = {
    city: 'city->fullname',
    age: 'age->name',
    purpose: 'purpose->name',
    profession: 'profession->name',
    income: 'income',
    createdAt: 'id',
    tags: 'id',
}

const getRequestData = (state, page) => {
    const data = {
        page: page,
        perPage: localStorage.getItem('perPage') || 20,
        search: state.advancedSearch,
        conditions: [],
        sort: {}
    };

    if (sortPairs[state.contactListSort] !== undefined) {
        data.sort[sortPairs[state.contactListSort]] = state.contactListSortDirection
    } else {
        console.log('Undefined kamo', state.contactListSort)
    }

    if (state.contactSearch.length >= 2) {
        data.conditions.push({
            key: "name",
            type: "fulltext",
            conjunction: "AND",
            minLength: 2,
            value: state.contactSearch,
        })
    }

    return data;
}