import api from '../api';

const userQuery = (params) => {
    return params.userId === null ? '' : '?userId=' + params.userId
}

export default {
    namespaced: true,
    state: {},
    actions: {
        detail(store, data = {}) {
            return new Promise((resolve, reject) => {
                api.get('milestone'  + userQuery(data)).then((response) => {
                    resolve(response.data)
                }).catch(() => {
                    reject()
                });
            })
        },
        list(store, data = {}) {
            return new Promise((resolve, reject) => {
                api.post('milestone/list', data).then((response) => {
                    resolve(response.data)
                }).catch(() => {
                    reject()
                });
            })
        },
        update(store, data) {
            api.put('milestone' + userQuery(data), data).then(() => {
                store.dispatch('alert/success', 'Milestone was updated', {root: true});
            }).catch((error) => {
                store.dispatch('alert/error', error.response.data.status, {root: true});
            })
        },
        save(store, data) {
            api.put('milestone/save' + userQuery(data), data).then(() => {
                store.dispatch('alert/success', 'Milestone was updated', {root: true});
            }).catch((error) => {
                store.dispatch('alert/error', error.response.data.status, {root: true});
            })
        },
        remove(store, data) {
            api.put('milestone/delete' + userQuery(data), data).then(() => {
                store.dispatch('alert/success', 'Milestone was updated', {root: true});
            }).catch((error) => {
                store.dispatch('alert/error', error.response.data.status, {root: true});
            })
        },
    },
    mutations: {}
}
