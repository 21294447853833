import api from '../api';

const baseUrl = 'system-element-attendance';

export default {
    namespaced: true,
    state: {
        loadingAttendance: false,
        attendance: [],
    },
    actions: {
        list(store, id) {
            store.state.attendance = []
            store.state.loadingAttendance = true
            return api.getItem(store, baseUrl + '/list/' + id, (response) => {
                store.state.attendance = response.data
                store.state.loadingAttendance = false
            });
        },
        add(store, data) {
            return api.postItem(store, baseUrl + '/' + data.id, data, 'Attendance was created!', function () {
                store.dispatch('list', data.id)
            })
        },
        cancel(store, id) {
            return api.editItem(store, baseUrl + '/cancel/' + id, {}, 'Attendance was updated!')
        }
    },
    mutations: {

    }
}
