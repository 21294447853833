<template>
    <div class="tags-input">
        <vue-tags-input
            v-model="tag"
            :tags="value"
            :autocomplete-items="autocompleteItems"
            :add-only-from-autocomplete="onlyFromAutocomplete"
            @tags-changed="update"
            :placeholder="t('Add tag')"
        />
    </div>
</template>

<script>
import {abstractField} from 'vue-form-generator'
import FormMixin from "vue-titan-form/src/form/FormMixin";
import VueTagsInput from '@johmun/vue-tags-input';

export default {
    name: "TagInput",
    mixins: [abstractField, FormMixin],
    components: {
        VueTagsInput,
    },
    computed: {
        onlyFromAutocomplete() {
            return this.schema.onlyFromAutocomplete === undefined ? false : this.schema.onlyFromAutocomplete
        },
        userId() {
            return this.$store.state.account.user === null ? 0 : this.$store.state.account.user.id
        },
    },
    watch: {
        'tag': 'initItems',
    },
    mounted() {
        this.value = this.mapValues(this.value)
    },
    methods: {
        update(newTags) {
            this.autocompleteItems = [];
            this.value = this.mapValues(newTags)
        },
        mapValues(tags) {
            return tags.map(item => {
                return {
                    id: item.id || null,
                    text: item.text,
                    userId: item.userId,
                    classes: item.userId === this.userId ? 'my-tag' : '',
                }
            })
        },
        initItems() {
            if (this.tag.length < 2) return;

            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.$store.dispatch('codeBook/tag', this.tag).then((response) => {
                    this.autocompleteItems = this.mapValues(response)
                })
            }, 600);
        },

    },
    data() {
        return {
            tag: '',
            autocompleteItems: [],
            debounce: null,
        };
    },
}
</script>

<style lang="scss" scoped>
.tags-input {
    width: 100%;

    ::v-deep {
        .vue-tags-input {
            max-width: 100%;
            width: 100%;

            .ti-input {
                border-radius: 0.25rem;
                border: 1px solid #ced4da;
            }

            &.ti-focus .ti-input {
                border-color: #7bceff;
            }
        }

        .ti-tag {
            background: #039dfa;
        }

        .my-tag {
            background: #3ac165;
        }
    }
}
</style>