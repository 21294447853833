import api from '../api';

const baseUrl = 'meeting';

export default {
    namespaced: true,
    state: {
        item: null,
    },
    actions: {
        list(store, data) {
            return api.list(store, baseUrl + '/list', data);
        },
        detail(store, id) {
            store.commit('resetItem')
            return api.getItem(store, baseUrl + '/' + id).then((data) => {
                store.commit('setItem', data);
            })
        },
        create(store, data) {
            return api.create(store, baseUrl, data)
        },
        addMembers(store, data) {
            return api.edit(store, baseUrl + '/add-members', data)
        },
        addTask(store, data) {
            return api.edit(store, baseUrl + '/add-task', data)
        },
        editTask(store, data) {
            return api.edit(store, baseUrl + '/edit-task', data)
        },
        deleteTask(store, id) {
            return new Promise((resolve, reject) => {
                api.delete(baseUrl + '/delete-task/' + id).then(() => {
                    store.dispatch('alert/success', 'Task was deleted', {root: true});
                    resolve()
                })
            })
        },
        edit(store, data) {
            return api.edit(store, baseUrl, data)
        },
        editPrivateNote(store, data) {
            return api.edit(store, baseUrl + '/private-note', data)
        },
        toggleTaskDone(store, id) {
            return new Promise((resolve, reject) => {
                api.put(baseUrl + '/toggle-task-done/' + id, {}).then((response) => {
                    store.commit('setItem', response.data);
                    resolve(response.data)
                }).catch((error) => {
                    store.dispatch('alert/error', error.response.data.status, {root: true});
                    reject()
                });
            })
        },
    },
    mutations: {
        setItem(state, data) {
            state.item = {...data};
        },
        resetItem(state) {
            state.item = null;
        },
    }
}
