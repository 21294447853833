import api, {defaultPaginator} from '../api';

const baseUrl = 'contact-kanban'
const kanbanViewType = 'kanbanViewType'

export default {
    namespaced: true,
    state: {
        viewType: localStorage.getItem(kanbanViewType) || 'detailed', // simple | detailed
        openedId: null,
        historyList: {
            loading: false,
            items: [],
            paginator: {...defaultPaginator},
        },
        selectedHistory: [],
        kanban: {
            loading: true,
            contactBySteps: {},
            callparties: [],
            notes: [],
            reminders: [],
            calendarEvents: [],
            changedOnCallParty: [],
            longOpened: [],
            userId: null,
        },
        selectedContactKanban: [],
    },
    actions: {
        create(store, data) {
            return api.postItem(store, baseUrl, data, null, (response) => {
                if (response.data.status === 'Created') {
                    store.dispatch('alert/success', 'Contact was created!', {root: true});
                } else {
                    store.dispatch('alert/warning', 'Contact already is in kanban!', {root: true});
                }
            })
        },
        kanban(store, data) {
            const callParty = data.callParty ? '/' + data.callParty : '';
            const userId = data.userId === null ? '' : '?userId=' + data.userId;

            store.state.kanban.loading = true

            return api.getItem(store, baseUrl + '/kanban/' + data.id + callParty + userId, ({data}) => {

                store.state.kanban = {
                    ...data,
                    loading: false,
                }
            })
        },
        remove(store, id) {
            return api.deleteByUrl(store, 'contact-kanban/' + id, 'Kanban contact was deleted!', null, true);
        },
        archive(store, id) {
            return api.editItem(store, 'contact-kanban/hide/' + id, {}, 'Kanban contact was hidden!', null, null, true)
        },
        hideMultiple(store, ids) {
            return api.editItem(store, baseUrl + '/archive-all', {ids: ids}, 'Multiple kanban contact was hidden!')
        },
        show(store, id) {
            return api.editItem(store, baseUrl + '/show/' + id, {}, 'Kanban contact is visible again!')
        },
        setEmoticon(store, data) {
            return api.editItem(store, baseUrl + '/emoticon/' + data.id, data, 'Emoticon was updated!')
        },
        changeStep(store, data) {
            return api.editItem(
                store,
                'contact-kanban/change-step/' + data.kanbanContactId + '/' + data.stepId,
                {
                    stepId: data.stepId,
                    kanbanContactId: data.kanbanContactId,
                    kanbanStepFailStatus: data.kanbanStepFailStatusId,
                    status: data.status === undefined ? data.kanbanStepFailStatusId === null : data.status,
                    order: data.order || 0,
                    movedOnCallPartyId: data.callPartyId
                },
                'Contact was moved to new step!',
            );
        },
        order(store, data) {
            return api.editItem(store, baseUrl + '/order/' + data.kanbanContactId, data, 'Contact was sorted!')
        },
        metric(store, data) {
            const page = data.page || 1
            const perPage = data.perPage || 10
            const metric = data.metric || 1

            return api.postItem(store, baseUrl + '/metric/' + metric + '/' + page + '/' + perPage, data)
        },
        contact(store, contactId) {
            return api.getItem(store, baseUrl + '/contact/' + contactId)
        },
        events(store, contactKanbanId) {
            return api.getItem(store, baseUrl + '/calendar-event/' + contactKanbanId)
        },
        history(store, data) {
            store.state.historyList.loading = true

            return api.getItem(store, baseUrl + '/history/' + data.year + '/' + data.month, ({data}) => { // ok
                store.commit('setHistoryList', data)
            })
        },
    },
    mutations: {
        open(state, id) {
            state.openedId = state.openedId === id ? null : id
        },
        setViewType(state, type) {
            localStorage.setItem(kanbanViewType, type)
            state.viewType = type
        },
        toggleSelectedHistory(state, id) {
            const index = state.selectedHistory.indexOf(id)
            index === -1 ? state.selectedHistory.push(id) : state.selectedHistory.splice(index, 1)
        },
        setHistoryList(state, data) {
            state.historyList.items = data
            state.historyList.loading = false
        },
        toggle(state, id) {
            const index = state.selectedContactKanban.indexOf(id)
            index === -1 ? state.selectedContactKanban.push(id) : state.selectedContactKanban.splice(index, 1)
        }
    }
}
