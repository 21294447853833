import api from '../api';

const baseUrl = 'report'

const loadReport = (store, slug, data, callback = null) => {
    return api.postItem(store, baseUrl + '/' + slug, data, null, callback)
}

export default {
    namespaced: true,
    state: {
        kanbanStepFilter: {}
    },
    actions: {
        resetFilter(store) {
            return loadReport(store, 'reset-filter', {})
        },
        performanceKpi(store, data) {
            return loadReport(store, 'performance-kpi', data)
        },
        metricLoss(store, data) {
            return loadReport(store, 'metric-loss', data)
        },
        goals(store, data) {
            return loadReport(store, 'goals', data)
        },
        ranking(store, data) {
            return loadReport(store, 'ranking', data)
        },
        correlation(store, data) {
            return loadReport(store, 'correlation', data)
        },
        salary(store, data) {
            return loadReport(store, 'money', data)
        },
        activities(store, data) {
            return loadReport(store, 'activities', data)
        },
        reportSource(store, data) {
            return loadReport(store, 'income-source', data)
        },
        timeDistribution(store, data) {
            return loadReport(store, 'time-distribution', data)
        },
        milestones(store, data) {
            const url = data.showFilter === true ? 'milestones' : 'milestones?ignoreStoredFilter=1'
            return loadReport(store, url, data)
        },
        personsEarned(store, data) {
            return loadReport(store, 'persons-earned', data)
        },
        monthResults(store, data) {
            const url = data.showFilter === true ? 'month-results' : 'month-results?ignoreStoredFilter=1'
            return loadReport(store, url, data)
        },
        sustainability(store, data) {
            return loadReport(store, 'sustainability', data)
        },
        scoreboard(store, data) {
            return loadReport(store, 'scoreboard', data)
        },
        callparty(store, data) {
            return loadReport(store, 'callparty', data)
        },
        metricMatrix(store, data) {
            return loadReport(store, 'metric-matrix', data)
        },
        dashboard(store, data) {
            return loadReport(store, 'dashboard', data)
        },
        database(store, data) {
            return loadReport(store, 'database', data)
        },
        kanbanStepDayDiff(store, data) {
            return loadReport(store, 'kanban-step-day-diff', data)
        },
        reportKanbanLoss(store, data) {
            return loadReport(store, 'kanban-loss', data)
        },
        userFluctuation(store, data) {
            return loadReport(store, 'user-fluctuation', data)
        },
        kanbanSteps(store, data) {
            return loadReport(store, 'kanban-steps', data, ({data}) => {
                store.commit('initKanbanStepFilter', data.data.kanbans)
            })
        },
    },
    mutations: {
        initKanbanStepFilter(state, kanbans) {
            kanbans.forEach((kanban) => {
                const type = kanban.kanban.slug
                const storageValue = localStorage.getItem('kanban-step-filter-' + type)
                state.kanbanStepFilter[type] = storageValue === null ? 1 : parseInt(storageValue)
                state.kanbanStepFilter = {...state.kanbanStepFilter}
            })
        },
        setKanbanStepFilter(state, data) {
            state.kanbanStepFilter[data.type] = data.value
            localStorage.setItem('kanban-step-filter-' + data.type, data.value)
        }
    },
    getters: {
    },
}

export const supportedColors = [
    '#6f42c1',
    '#2564c3',
    '#33b333',
    '#f4b404',
    '#FE8B02',
    '#b22032'
]

export const selectSupportedColor = (index) => {
    const supportedColorsCount = supportedColors.length
    const colorIndex = index < supportedColorsCount ? index : index % supportedColorsCount;
    return supportedColors[colorIndex]
}

export const reports = (t, account) => {
    return [
        {
            name: t('Callparty'),
            slug: 'callparty',
            route: {
                name: 'report',
                params: {
                    slug: 'callparty',
                }
            }
        }, {
            name: t('Database report'),
            slug: 'database',
            route: {
                name: 'report',
                params: {
                    slug: 'database',
                }
            }
        }, {
            name: t('Kanban'),
            slug: 'kanban-steps',
            route: {
                name: 'report',
                params: {
                    slug: 'kanban-steps',
                }
            }
        }, {
            name: t('Correlation report'),
            slug: 'correlation',
            route: {
                name: 'report',
                params: {
                    slug: 'correlation',
                }
            }
        }, {
            name: t('Metric'),
            slug: 'metric',
            route: {
                name: 'report',
                params: {
                    slug: 'metric',
                }
            }
        }, {
            name: t('Month results'),
            slug: 'month-results',
            route: {
                name: 'report',
                params: {
                    slug: 'month-results',
                }
            }
        }, {
            name: t('Milestones'),
            slug: 'milestones',
            route: {
                name: 'report',
                params: {
                    slug: 'milestones',
                }
            }
        }, {
            name: t('Results report'),
            slug: 'results',
            route: {
                name: 'report',
                params: {
                    slug: 'results',
                }
            }
        }, {
            name: t('Salary'),
            slug: 'salary',
            route: {
                name: 'report',
                params: {
                    slug: 'salary',
                }
            }
        }, {
            name: t('Goals report'),
            slug: 'goals',
            route: {
                name: 'report',
                params: {
                    slug: 'goals',
                }
            }
        }, {
            name: t('Scoreboard'),
            slug: 'scoreboard',
            route: {
                name: 'report',
                params: {
                    slug: 'scoreboard',
                }
            }
        }, {
            name: t('Sustainability'),
            slug: 'sustainability',
            route: {
                name: 'report',
                params: {
                    slug: 'sustainability',
                }
            }
        }, {
            name: t('Kanban funnel'),
            slug: 'performance-kpi',
            route: {
                name: 'report',
                params: {
                    slug: 'performance-kpi',
                }
            }
        }, {
            name: t('Income source'),
            slug: 'income-source',
            route: {
                name: 'report',
                params: {
                    slug: 'income-source',
                }
            }
        }, {
            name: t('KanbanStepDayDiff'),
            slug: 'kanban-step-day-diff',
            route: {
                name: 'report',
                params: {
                    slug: 'kanban-step-day-diff',
                }
            }
        }, {
            name: t('KanbanLoss'),
            slug: 'kanban-loss',
            route: {
                name: 'report',
                params: {
                    slug: 'kanban-loss',
                }
            }
        }, {
            name: t('User fluctuation'),
            slug: 'user-fluctuation',
            route: {
                name: 'report',
                params: {
                    slug: 'user-fluctuation',
                }
            }
        }, {
            name: t('Time distribution'),
            slug: 'time-distribution',
            route: {
                name: 'report',
                params: {
                    slug: 'time-distribution',
                }
            }
        }, {
            name: t('Ranking'),
            slug: 'ranking',
            route: {
                name: 'report',
                params: {
                    slug: 'ranking',
                }
            }
        }
    ]
}
