import api from '../api';

export default {
    namespaced: true,
    state: {
    },
    actions: {
        name(store, id) {
            return api.getItem(store, 'structure-type/name/' + id)
        },
    },
    mutations: {

    }
}
