import api from '../api';

export default {
    namespaced: true,
    state: {
        uploading: false,
    },
    actions: {
        search(store, data) {
            return new Promise((resolve, reject) => {
                api.post('gallery/search', data).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    store.dispatch('alert/error', error.response.data.status, {root: true});
                    reject()
                });
            })
        },
        uploadImage(store, data) {
            return new Promise((resolve, reject) => {
                store.commit('setUploading', true);
                api.post('gallery/upload', data).then((response) => {
                    store.dispatch('alert/success', 'Image was uploaded!', {root: true});
                    store.commit('setUploading', false);
                    resolve()
                }).catch((error) => {
                    store.dispatch('alert/error', error.response.data.status, {root: true});
                    store.commit('setUploading', false);
                    reject()
                });
            });
        },
    },
    mutations: {
        setUploading(state, value) {
            state.uploading = value
        },
    }
}
