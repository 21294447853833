import api, {defaultPaginator} from '../api';

const baseUrl = 'group';

const defaultForm = {
    id: null,
    name: '',
    groupUser: [],
};

export default {
    namespaced: true,
    state: {
        item: null,
        itemForm: {...defaultForm},
        groupList: {
            loading: false,
            items: [],
            paginator: {...defaultPaginator},
        },
    },
    actions: {
        name(store, id) {
            return api.getItem(store, baseUrl + '/name/' + id)
        },
        list(store, data) {
            store.commit('setListLoading', data.page)
            api.listData(store, baseUrl + '/list/' + data.page + '/20', data).then((data) => {
                store.commit('setList', data)
            })
        },
        detail(store, id) {
            store.commit('resetItem')
            return api.getItem(store, baseUrl + '/' + id).then((data) => {
                store.commit('setItem', data);
            })
        },
        create(store, data) {
            return api.create(store, baseUrl, data)
        },
        edit(store, data) {
            return api.edit(store, baseUrl, data)
        },
        removeById(store, id) {
            return api.deleteByUrl(store, baseUrl + '/' + id, 'Group was deleted')
        }
    },
    mutations: {
        setItem(state, data) {
            state.item = {...data};
            state.itemForm = {...data};
        },
        resetItem(state) {
            state.item = null;
            state.itemForm = {...defaultForm};
        },
        setListLoading(state, page) {
            state.groupList.loading = true
            if (page === 1) {
                state.groupList.items = []
            }
        },
        setList(state, data) {
            state.groupList.items = data.paginator.page === 1
                ? data.items
                : [...state.groupList.items, ...data.items]

            state.groupList.paginator = data.paginator
            state.groupList.loading = false
        },
    }
}
