<template>
    <div class="users-select">
        <div class="fields-wrapper">
            <div class="users-select-view">
                <b-dropdown :text="t('userSelect-' + view)" variant="light" left>
                    <b-dropdown-item-button v-for="type in ['reportUserTree', 'reportTree', 'companyTree', 'group']"
                                            :key="type"
                                            @click="changeView(type)"
                    >
                        {{ t('userSelect-' + type) }}
                    </b-dropdown-item-button>
                </b-dropdown>
            </div>
            <div class="users-select-input">
                <div class="loading-wrapper" v-if="codeBookLoading === true">
                    <titan-loading size="xs"/>
                </div>
                <template v-if="codeBookLoading === false">
                    <treeselect v-model="value.users"
                                placeholder=""
                                :multiple="true"
                                :options="userCodeBook"
                                :required="required"
                                valueConsistsOf="LEAF_PRIORITY"
                                v-if="view !== 'group'"
                    />
                    <multiselect
                        class="titan-custom-select"
                        v-model="groupValue"
                        :options="groupCodeBook"
                        :label="'name'"
                        :disabled="false"
                        :placeholder="schema.placeholder === undefined ? '' : schema.placeholder"
                        :required="false"
                        :preselectFirst="false"
                        trackBy="id"
                        :multiple="false"
                        tagPlaceholder=""
                        selectLabel=""
                        selectedLabel=""
                        deselectLabel=""
                        @select="value.group = $event.id"
                        @remove="value.group = null"
                        v-if="view === 'group'"
                    >
                        <template slot="singleLabel" slot-scope="props">
                            <span v-html="customTemplate(props.option, 'singleLabel')"></span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <span v-html="customTemplate(props.option, 'option')"></span>
                        </template>
                        <template slot="noResult">
                            {{ i18n('No results found') }}
                        </template>
                    </multiselect>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import {abstractField} from 'vue-form-generator'
import {TitanLoading} from "vue-titan-elements";
import Multiselect from 'vue-multiselect'
import FormMixin from "vue-titan-form/src/form/FormMixin";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {treeSelectNew} from "@/store/modules/codeBook";

export const userSelectValidator = (field, value) => {
    if (field.required === false) {
        return [];
    }
    if (value.view === 'group') {
        return value.group === null ? ['This field is required!'] : []
    }
    return value.users.length === 0 ? ['This field is required!'] : [];
}

export default {
    mixins: [abstractField, FormMixin],
    components: {
        Treeselect,
        Multiselect,
        TitanLoading
    },
    computed: {
        required() {
            return this.schema.required === undefined ? false : this.schema.required
        },
        values: {
            cache: false,
            get() {
                if (typeof this.schema.values === 'function') {
                    return this.schema.values();
                } else if (this.schema.codeBook !== undefined && this.schema.codeBook !== null) {
                    return this.$store.getters['codeBook/get'](this.schema.codeBook)
                }
                return this.schema.values === undefined ? [] : this.schema.values
            }
        },
    },
    mounted() {
        this.view = this.value === undefined ? 'reportTree' : this.value.view
        this.groupValue = this.value.group
        this.loadCodeBook()
    },
    methods: {
        loadCodeBook() {
            if (this.view === 'group' && this.groupCodeBook === null) {
                this.codeBookLoading = true
                this.$store.dispatch('codeBook/load', 'group').then((data) => {
                    this.groupCodeBook = data.group

                    if (Number.isInteger(this.groupValue)) {
                        this.groupValue = data.group.find(item => {
                            return item.id === this.groupValue
                        })
                    }

                    this.codeBookLoading = false
                })
            } else if (['companyTree', 'reportTree'].includes(this.view)) {
                this.codeBookLoading = true

                const params = {
                    type: this.view === 'companyTree' ? 'company' : 'report'
                }

                // todo: month / year

                this.$store.dispatch('codeBook/structureTree', params).then((data) => {
                    this.userCodeBook = treeSelectNew(data)
                    this.codeBookLoading = false
                })
            } else if (this.view === 'reportUserTree') {
                this.codeBookLoading = true
                this.$store.dispatch('codeBook/load', ['reportUserTree']).then((data) => {
                    this.userCodeBook = treeSelectNew([data.reportUserTree])
                    this.codeBookLoading = false
                })
            }
        },
        changeView(view) {
            this.view = view
            this.value.view = view
            this.value.group = null
            this.value.users = []
            this.loadCodeBook()
        },
        customTemplate(item, type) {
            return this.schema[type] === undefined ? item.name : this.schema[type](item)
        },
    },
    data() {
        return {
            groupCodeBook: null,
            userCodeBook: null,
            view: 'reportTree',
            groupValue: null,
            reloading: false,
            codeBookLoading: true,
        }
    }
}
</script>
<style lang="scss" scoped>

.users-select {
    width: 100%;
    min-height: 38px;

    .fields-wrapper {
        display: flex;
        flex-direction: row;

        .users-select-view {
            margin-right: 10px;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;

            .users-select-view {
                margin-bottom: 5px;
                margin-right: 0;
            }
        }
    }


    ::v-deep {
        .titan-custom-select {
            min-height: 36px ! important;
            width: 100%;

            .multiselect__input {
                min-height: 18px;
            }

            &.multiselect--disabled {
                .multiselect__select {
                    display: none;
                }
            }

            .multiselect__single {
                margin-bottom: 6px;
            }

            .multiselect__content-wrapper {
                top: 100%;
            }

            .multiselect__content-wrapper,
            .multiselect__tags {
                border-color: #ced4da;
            }

            .multiselect__tags {
                min-height: 36px;
            }
        }
    }

    .users-select-input {
        flex: 1;

        .loading-wrapper {
            height: 36px;
        }
    }

    .users-select-view {
        ::v-deep {
            .dropdown {
                display: block;
            }

            .dropdown-toggle {
                height: 36px;
                background: #fff;
                border: 1px solid #ced4da;
                padding: 0 10px 0 15px;
                outline: none ! important;
                box-shadow: none ! important;
                border-radius: 0.25rem;
                width: 100%;
                text-align: left;

            }
        }
    }
}
</style>
