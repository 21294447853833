import api from './../api';

const baseUrl = 'user'

const defaultUser = {
    bio: '',
    blocked: false,
    position: null,
}

export default {
    namespaced: true,
    state: {
        item: {
            ...defaultUser
        },
    },
    getters: {
        itemPermission(state) {
            return function (aclId, type) {
                if (state.item.userAcl === undefined) {
                    return false;
                }
                const found = state.item.userAcl.find(function (item) {
                    return item.acl.id === aclId;
                });
                return found !== undefined && found.value.includes(type);
            }
        },
    },
    actions: {
        profile(store, userId) {
            return api.getItem(store, 'user/profile/' + userId)
        },
        list(store, params) {
            return new Promise((resolve, reject) => {
                api.post('user/children', params).then(response => {
                    resolve({
                        items: response.data.users,
                        stats: response.data.stats,
                        paginator: {
                            pages: 1,
                            page: 1,
                        }
                    });
                }).catch(error => {
                    reject(error.response);
                });
            });
        },
        get(store, id) {
            return api.getItem(store, baseUrl + '/' + id, ({data}) => {
                store.commit('setItem', data);
            })
        },
        block(store, id) {
            return api.editItem(store, baseUrl + '/block/' + id, {}, 'User was blocked', () => {
                store.commit('resetItem');
            })
        },
        create(store) {
            return api.postItem(store, baseUrl, store.state.item, 'User was created', ({data}) => {
                store.commit('setItem', data);
            }, false)
        },
        update(store, id) {
            return api.editItem(store, baseUrl + '/' + id, store.state.item, 'User was updated', null, 'User was NOT updated')
        },
        name(store, id) {
            return api.getItem(store, 'user/name/' + id)
        },
    },
    mutations: {
        setProfile(state, data) {
            state.user = data;
        },
        setItem(state, data) {
            state.item = data;
        },
        resetItem(state) {
            state.item = {...defaultUser};
        },
        changePermission(state, data) {
            let found = state.item.userAcl.find((item) => item.acl.id === data.item.id);
            if (found !== undefined) {
                if (data.value === true) {
                    found.value = data.type === 'CRUD' ? 'CRUD' : (found.value += data.type);
                } else {
                    found.value = found.value.replace(data.type, '');
                    if (found.value === '') {
                        const foundIndex = state.item.userAcl.findIndex((item) => item.acl.id === data.item.id);
                        state.item.userAcl.splice(foundIndex, 1);
                    }
                }
            } else {
                state.item.userAcl.push({
                    value: data.type,
                    acl: data.item
                })
            }
        }
    }
}
