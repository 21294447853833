import api from '../api';

const baseUrl = 'callscript'

export default {
    namespaced: true,
    state: {
        callScript: null,
        loading: true,
    },
    actions: {
        list(store, data) {
            return api.list(store, 'callscript/list', data)
        },
        detail(store, id) {
            store.commit('resetItem')
            return api.getItem(store, baseUrl + '/' + id, (data) => {
                store.commit('setItem', data);
            })
        },
        contact(store, params) {
            store.commit('resetItem')
            return api.getItem(store, baseUrl + '/contact/' + params.kanbanId + '/' + params.contactId, ({data}) => {
                store.commit('setItem', data);
            });
        },
        create(store, data) {
            return api.create(store, 'callscript', data);
        },
        edit(store, data) {
            return api.edit(store, 'callscript', data);
        },
        remove(store, id) {
            return api.deleteById(store, 'callscript', id);
        },
    },
    mutations: {
        setItem(state, data) {
            state.callScript = {...data};
        },
        resetItem(state) {
            state.callScript = null;
        },
    }
}
