import api, {defaultPaginator} from '../api';

const baseUrl = 'contact'

export default {
    namespaced: true,
    state: {
        serviceList: {
            loading: false,
            items: [],
            paginator: {...defaultPaginator},
        },
        selected: [],
    },
    actions: {
        load(store, data) {
            store.commit('setListLoading', data.page)

            api.listData(store, baseUrl + '/service-list/' + data.page + '/20', data).then((data) => {
                store.commit('setList', data)
            })
        },
    },
    mutations: {
        setListLoading(state, page) {
            state.serviceList.loading = true
            if (page === 1) {
                state.serviceList.items = []
            }
        },
        setList(state, data) {
            state.serviceList.items = data.paginator.page === 1 ? data.items : [...state.serviceList.items, ...data.items]
            state.serviceList.paginator = data.paginator
            state.serviceList.loading = false
        },
        toggleSelected(state, id) {
            const index = state.selected.indexOf(id)
            index === -1 ? state.selected.push(id) : state.selected.splice(index, 1)
        },
    }
}
